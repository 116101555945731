import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import BlockchainService from '../services/blockchainService';
import { useChainContext } from '../contexts/ChainContext';
import { FileText, CheckCircle, User, AlertOctagon, Loader2 } from 'lucide-react';

const ProposalDetails = () => {
  const { proposer, proposalName } = useParams();
  const [proposal, setProposal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentChain } = useChainContext();
  const blockchainService = useMemo(() => new BlockchainService(currentChain), [currentChain]);

  useEffect(() => {
    const fetchProposalDetails = async () => {
      try {
        const response = await blockchainService.fetchFromChain(`/proposal/${proposer}/${proposalName}`);
        setProposal(response);
      } catch (err) {
        setError('Error fetching proposal details.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProposalDetails();
  }, [proposer, proposalName, blockchainService]);

  if (loading) {
    return (
      <div className="text-center py-8">
        <Loader2 className="animate-spin mx-auto mb-2" />
        <span className="text-gray-600">Loading proposal details...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-500">
        <AlertOctagon className="mx-auto mb-2" size={24} />
        <span>{error}</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">{proposalName}</h1>
              <div className="flex items-center mt-2 text-gray-600">
                <User size={16} className="mr-2" />
                <span className="mr-4">{proposer}</span>
                <span>
                  {proposal.approvals.provided_approvals.length}/
                  {proposal.approvals.requested_approvals.length + proposal.approvals.provided_approvals.length} approvals
                </span>
              </div>
            </div>
            <div className={`px-4 py-2 rounded-full ${
              proposal.proposal.status === 'EXECUTED' 
                ? 'bg-green-100 text-green-800' 
                : 'bg-orange-100 text-orange-800'
            } flex items-center`}>
              {proposal.proposal.status === 'EXECUTED' ? (
                <CheckCircle size={16} className="mr-2" />
              ) : (
                <AlertOctagon size={16} className="mr-2" />
              )}
              {proposal.proposal.status}
            </div>
          </div>

          <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-lg shadow-md mb-6">
            <div className="bg-white p-4 rounded-lg">
              <h2 className="text-xl font-semibold mb-4 text-gray-800">Transaction Details</h2>
              <pre className="bg-gray-900 text-gray-100 p-4 rounded-lg text-sm overflow-auto">
                {JSON.stringify(proposal.proposal.unpacked_transaction, null, 2)}
              </pre>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold mb-4 text-gray-800 flex items-center">
                <AlertOctagon size={20} className="mr-2 text-orange-500" />
                Pending Approvals
              </h3>
              <div className="space-y-2">
                {proposal.approvals.requested_approvals.map((approval) => (
                  <div 
                    key={`requested-${approval.level.actor}-${approval.level.permission}`}
                    className="flex justify-between items-center p-3 bg-gray-50 rounded-lg border border-gray-200"
                  >
                    <span className="text-gray-700">{approval.level.actor}@{approval.level.permission}</span>
                    <span className="text-orange-600 font-medium">PENDING</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold mb-4 text-gray-800 flex items-center">
                <CheckCircle size={20} className="mr-2 text-green-500" />
                Provided Approvals
              </h3>
              <div className="space-y-2">
                {proposal.approvals.provided_approvals.map((approval) => (
                  <div 
                    key={`provided-${approval.level.actor}-${approval.level.permission}`}
                    className="flex justify-between items-center p-3 bg-gray-50 rounded-lg border border-gray-200"
                  >
                    <span className="text-gray-700">{approval.level.actor}@{approval.level.permission}</span>
                    <span className="text-green-600 font-medium">APPROVED</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-6 text-sm text-gray-500 text-center">
            Expiration: {new Date(proposal.proposal.expiration).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalDetails;