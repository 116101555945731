import React, { useState, useEffect, useCallback } from 'react';
import { useChainContext } from '../../../contexts/ChainContext';
import { RefreshCw } from 'lucide-react';
import { getChainConfig } from '../../../config/chainConfig';

const RefundCpuNet = ({ refund, onTransactionSuccess }) => {

  const { currentChain, user } = useChainContext();


  // Calcular si han pasado 3 días desde la solicitud
  //const requestDate = new Date(refund.request_time + 'Z');
  const requestDate = refund ? new Date(refund.request_time + 'Z') : new Date();
  const currentTime = new Date();
  const timePassed = currentTime - requestDate;
  const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;

  let timeMessage;
  if (timePassed >= threeDaysInMs) {
    timeMessage = "Time Left: Refund available!";
  } else {
    const timeLeft = threeDaysInMs - timePassed;
    const hoursLeft = Math.ceil(timeLeft / (1000 * 60 * 60));
    timeMessage = `Time Left: ${hoursLeft} hours left until refund is available.`;
  }

  
  const [isProcessing, setIsProcessing] = useState(false);
  const [notification, setNotification] = useState(null);

  const handleRefund = async () => {
    setIsProcessing(true);
    setNotification(null);

    try {
      if (!user || !user.session) {
        throw new Error('User not authenticated');
      }

      const action = {
        account: 'eosio',
        name: 'refund',
        authorization: [{ actor: user.name, permission: 'active' }],
        data: {
          owner: user.name
        }
      };

      

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      

      setNotification({ message: 'Refund successfully processed.', type: 'success' });
      onTransactionSuccess(); // Trigger callback after successful transaction
    } catch (error) {
      console.error('Error processing refund:', error);
      setNotification({ message: `Failed to process refund: ${error.message}`, type: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-8xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Refund CPU/NET</h1>
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
          <div className="bg-white p-6 rounded-2xl">
            <div className="space-y-6">
            {refund ? (
                <div>
                  <h2 className="text-xl font-semibold mb-2">Pending Refund</h2>
                  <p>CPU: {parseFloat(refund.cpu_amount.split(' ')[0]).toFixed(4)} WAX</p>
                  <p>NET: {parseFloat(refund.net_amount.split(' ')[0]).toFixed(4)} WAX</p>
                  <p>Available on: {new Date(refund.request_time + 'Z').toLocaleString()}</p>
                  <p>{timeMessage}</p>
                </div>
              ) : (
                <div>
                  <h2 className="text-xl font-semibold mb-2">No Pending Refund</h2>
                  <p>There are currently no pending refunds for CPU/NET resources.</p>
                </div>
              )}
              <div className="flex items-center justify-between">
                <button
                  onClick={handleRefund}
                  className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center disabled:opacity-50"
                  disabled={isProcessing || (refund && parseFloat(refund.cpu_amount) === 0 && parseFloat(refund.net_amount) === 0)}

                >
                  <RefreshCw className="w-5 h-5 mr-2" />
                  {isProcessing ? 'Processing...' : 'Process Refund'}
                </button>
              </div>
            </div>
          </div>
        </div>

        {notification && (
          <div className={`mt-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default RefundCpuNet;