import React, { useState, useEffect, useCallback } from 'react';
import { useChainContext } from '../../contexts/ChainContext';
import BlockchainService from '../../services/blockchainService';
import { AlertCircle } from 'lucide-react';
import AccountSummary from '../../components/AccountSummary';
import ResourcesSection from '../../components/ResourcesSection';
import PermissionManager from './PermissionManager';
import LinkManager from '../../components/LinkManager';

const Keys = () => {
  const { currentChain, user } = useChainContext();
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userAccountInfo, setUserAccountInfo] = useState(null);
  const [notification, setNotification] = useState(null);
  const [userPermission, setUserPermission] = useState(null);
  const [showLinkManager, setShowLinkManager] = useState(false);

  const fetchAccountInfo = useCallback(async () => {
    if (!user || !user.name) {
      setError('User not authenticated');
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const blockchainService = new BlockchainService(currentChain);
      const accountInfo = await blockchainService.fetchFromChain(`/get_account/${user.name}`);
      if (accountInfo && accountInfo.account) {
        setUserAccountInfo(accountInfo);
        setPermissions(accountInfo.account.permissions);
        setError(null);
      } else {
        throw new Error('No account information found');
      }
    } catch (err) {
      console.error('Error fetching account info:', err);
      setError('Failed to fetch account information: ' + err.message);
    } finally {
      setLoading(false);
    }
  }, [currentChain, user]);

  useEffect(() => {
    fetchAccountInfo();
    getUserPermission();
  }, [fetchAccountInfo]);

  const getUserPermission = () => {
    if (user && user.session) {
      const permission = user.session.requestPermission;
      setUserPermission(permission);
    } else {
      console.error('User session not available');
      setNotification({ type: 'error', message: 'Failed to get user permission' });
    }
  };

  const handleUpdatePermission = async (updatedPermission) => {
    try {
      let action;
      if (updatedPermission.delete) {
        action = {
          account: 'eosio',
          name: 'deleteauth',
          authorization: [{ actor: user.name, permission: userPermission }],
          data: {
            account: user.name,
            permission: updatedPermission.perm_name
          }
        };
      } else {
        action = {
          account: 'eosio',
          name: 'updateauth',
          authorization: [{ actor: user.name, permission: userPermission }],
          data: {
            account: user.name,
            permission: updatedPermission.perm_name,
            parent: updatedPermission.parent,
            auth: updatedPermission.required_auth
          }
        };
      }
  
      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );
  
      setNotification({ type: 'success', message: updatedPermission.delete ? 'Permission deleted successfully' : 'Permission updated successfully' });
      fetchAccountInfo();
    } catch (error) {
      console.error('Error updating/deleting permission:', error);
      setNotification({ type: 'error', message: `Failed to update/delete permission: ${error.message}` });
    }
  };

  if (loading) return <div className="text-center py-8">Loading account information...</div>;
  if (error) return (
    <div className="text-center py-8 text-red-500 flex items-center justify-center">
      <AlertCircle className="mr-2" />
      <span>Error: {error}</span>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      {userAccountInfo && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <AccountSummary accountInfo={userAccountInfo.account} />
          <ResourcesSection accountInfo={userAccountInfo} />
        </div>
      )}
      
      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Current Permissions:</h2>
        </div>
        {permissions && permissions.length > 0 ? (
          <PermissionManager 
            permissions={permissions}
            userPermission={userPermission}
            onUpdatePermission={handleUpdatePermission}
          />
        ) : (
          <p>No permissions found.</p>
        )}
      </div>

      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">LINK / UNLINK</h2>
          <button
            onClick={() => setShowLinkManager(!showLinkManager)}
            className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
          >
            {showLinkManager ? 'Hide' : 'Show'} Link Manager
          </button>
        </div>
        {showLinkManager && (
          <LinkManager
            accountName={user.name}
            permissions={permissions}
            onUpdateLinks={fetchAccountInfo}
          />
        )}
      </div>

      {notification && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-md ${
          notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
        } text-white`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

export default Keys;