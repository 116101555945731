import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDown, ChevronUp, X, Link as ChainIcon, RadioTower, Users, Wallet, FileText, Combine, BarChart3, Database } from 'lucide-react';
import { useChainContext } from '../contexts/ChainContext';

function Sidebar({ isOpen, setSidebarOpen }) {
  const location = useLocation();
  const [walletOpen, setWalletOpen] = useState(false);
  const [topHoldersOpen, setTopHoldersOpen] = useState(false);
  const { user, isAuthenticated, currentChain } = useChainContext();

  const getTopHoldersSubItems = () => {
    if (currentChain === 'telos-mainnet') {
      return [
        { name: 'Total Amount', path: '/top-holders/total', icon: BarChart3 },
        { name: 'RAM', path: '/top-holders/ram', icon: Database },
        { name: 'REX', path: '/top-holders/rex', icon: Combine }
      ];
    } else if (currentChain === 'wax-mainnet') {
      return [
        { name: 'Total Amount', path: '/top-holders/total', icon: BarChart3 },
        { name: 'RAM', path: '/top-holders/ram', icon: Database }
      ];
    }
    return [];
  };

  const navItems = [
    { name: 'Monitor', path: '/', icon: RadioTower },
    { name: 'Account Detail', path: isAuthenticated ? `/account-detail/${user.name}` : '/account-detail', icon: Users },
    { 
      name: 'Wallet', 
      path: '/wallet',
      icon: Wallet,
      subItems: [
        { name: 'Transfer Tokens', path: '/wallet/transfer' },
        { name: 'Manage Resources (CPU/NET/RAM)', path: '/wallet/cpu-net-ram' },
        { name: 'Manage Keys', path: '/wallet/keys' },
        { name: 'Vote', path: '/wallet/vote' },
      ]
    },
    { name: 'Multisigs', path: '/multisig', icon: FileText },
    ...((['telos-mainnet', 'wax-mainnet'].includes(currentChain)) ? [{
      name: 'Top Holders',
      path: '/top-holders',
      icon: BarChart3,
      subItems: getTopHoldersSubItems()
    }] : [])
  ];

  return (
    <>
      <div
        className={`fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden ${
          isOpen ? 'block' : 'hidden'
        }`}
        onClick={() => setSidebarOpen(false)}
        aria-hidden="true"
      />

      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform bg-indigo-700 lg:translate-x-0 lg:static lg:inset-0 ${
          isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
        }`}
      >
        <button
          className="absolute top-4 right-4 text-gray-100 lg:hidden"
          onClick={() => setSidebarOpen(false)}
        >
          <X size={24} />
        </button>

        <div className="flex items-center justify-center h-20 bg-indigo-800">
          <ChainIcon size={24} className="text-indigo-300 mr-2" />
          <h1 className="text-2xl font-bold text-white tracking-wide">
            ChainScout<span className="text-indigo-300">.io</span>
          </h1>
        </div>

        <nav className="mt-6">
          {navItems.map((item) => (
            <div key={item.name}>
              {item.subItems ? (
                <div>
                  <button
                    onClick={() => {
                      if (item.name === 'Wallet') setWalletOpen(!walletOpen);
                      if (item.name === 'Top Holders') setTopHoldersOpen(!topHoldersOpen);
                    }}
                    className={`flex items-center justify-between w-full px-6 py-3 text-gray-100 hover:bg-indigo-800 hover:bg-opacity-25`}
                  >
                    <div className="flex items-center">
                      {item.icon && <item.icon size={20} className="mr-3" />}
                      <span>{item.name}</span>
                    </div>
                    {(item.name === 'Wallet' ? walletOpen : topHoldersOpen) ? 
                      <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </button>
                  {((item.name === 'Wallet' && walletOpen) || 
                    (item.name === 'Top Holders' && topHoldersOpen)) && (
                    <div className="space-y-1 ml-6">
                      {item.subItems.map((subItem) => (
                        <Link
                          key={subItem.name}
                          className={`flex items-center px-6 py-2 text-sm text-gray-100 ${
                            location.pathname === subItem.path
                              ? 'bg-indigo-800 bg-opacity-25'
                              : 'hover:bg-indigo-800 hover:bg-opacity-25'
                          }`}
                          to={subItem.path}
                        >
                          {subItem.icon && <subItem.icon size={16} className="mr-3" />}
                          <span>{subItem.name}</span>
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  className={`flex items-center px-6 py-3 text-gray-100 ${
                    location.pathname === item.path
                      ? 'bg-indigo-800 bg-opacity-25'
                      : 'hover:bg-indigo-800 hover:bg-opacity-25'
                  }`}
                  to={item.path}
                >
                  {item.icon && <item.icon size={20} className="mr-3" />}
                  <span>{item.name}</span>
                </Link>
              )}
            </div>
          ))}
        </nav>
      </div>
    </>
  );
}

export default Sidebar;