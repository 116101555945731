import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useChainContext } from '../../contexts/ChainContext';
import { ChevronDown, Send, DollarSign, X, ExternalLink, User, ArrowRight, Clipboard, MessageSquare, Search, AlertCircle } from 'lucide-react';
import { getChainConfig } from '../../config/chainConfig';
import BlockchainService from '../../services/blockchainService';
import { debounce } from 'lodash';
import AccountSummary from '../../components/AccountSummary';
import ResourcesSection from '../../components/ResourcesSection';

const Notification = ({ message, type, onClose }) => (
  <div className={`fixed top-4 right-4 p-4 rounded-md shadow-md ${
    type === 'success' ? 'bg-green-500' : 'bg-red-500'
  } text-white flex items-center justify-between max-w-md`}>
    <span className="mr-4">{message}</span>
    <button onClick={onClose} className="focus:outline-none">
      <X size={18} />
    </button>
  </div>
);

function TransferTokens() {
  const { currentChain, user } = useChainContext();
  const [to, setTo] = useState('');
  const [quantity, setQuantity] = useState('');
  const [token, setToken] = useState({ symbol: '', contract: '', precision: 0 });
  const [memo, setMemo] = useState('');
  const [isTransferring, setIsTransferring] = useState(false);
  const [notification, setNotification] = useState(null);
  const [completedTransaction, setCompletedTransaction] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [userAccountInfo, setUserAccountInfo] = useState(null);
  const [availableTokens, setAvailableTokens] = useState([]);
  
  const suggestionsRef = useRef(null);
  const toInputRef = useRef(null);
  const blockchainService = useRef(new BlockchainService(currentChain));

  const chainConfig = getChainConfig(currentChain);
  const blockExplorerUrl = chainConfig.blockExplorerUrl;

  

  useEffect(() => {
    blockchainService.current = new BlockchainService(currentChain);
  }, [currentChain]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target) && !toInputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        if (user && user.name) {
          const blockchainService = new BlockchainService(currentChain);
          const info = await blockchainService.fetchFromChain(`/get_account/${user.name}`);
          setUserAccountInfo(info);
          
          // Use the precision information from the account data
          const tokens = info.tokens.map(t => ({
            ...t,
            precision: t.precision !== undefined ? t.precision : 0
          }));
          
          setAvailableTokens(tokens);
          if (tokens.length > 0) {
            setToken({ 
              symbol: tokens[0].symbol, 
              contract: tokens[0].contract, 
              precision: tokens[0].precision 
            });
          }
        }
      } catch (err) {
        console.error('Error fetching user account info:', err);
      }
    };
  
    fetchAccountInfo();
  }, [user, currentChain]);

  const debouncedSearch = useRef(
    debounce(async (search) => {
      if (search.length >= 1) {
        setIsSearching(true);
        setSearchError('');
        try {
          const response = await blockchainService.current.searchAccounts(search);
          const accounts = response.rows.map(row => row.scope);
          setSuggestions(accounts);
          setShowSuggestions(accounts.length > 0);
        } catch (error) {
          console.error('Error searching accounts:', error);
          setSearchError('Failed to fetch account suggestions. Please try again.');
          setSuggestions([]);
        } finally {
          setIsSearching(false);
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
        setSearchError('');
      }
    }, 300)
  ).current;

  const handleToChange = (e) => {
    const value = e.target.value;
    setTo(value);
    debouncedSearch(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setTo(suggestion);
    setShowSuggestions(false);
    toInputRef.current.focus();
  };

  const formatNumber = (number, precision) => {
    return Number(number).toFixed(precision);
  };

  const formattedQuantity = useMemo(() => {
    if (!quantity) return '0.' + '0'.repeat(token.precision);
    return formatNumber(parseFloat(quantity), token.precision);
  }, [quantity, token.precision]);

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    const regex = new RegExp(`^\\d*\\.?\\d{0,${token.precision}}$`);
    if (regex.test(value) || value === '') {
      setQuantity(value);
    }
  };

  const reloadUserData = useCallback(async () => {
    const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    try {
      if (user && user.name) {

        await wait(2000);

        const blockchainService = new BlockchainService(currentChain);
        const info = await blockchainService.fetchFromChain(`/get_account/${user.name}`);
        setUserAccountInfo(info);
        
        const tokens = info.tokens.map(t => ({
          ...t,
          precision: t.precision !== undefined ? t.precision : 0
        }));
        
        setAvailableTokens(tokens);
      }
    } catch (err) {
      console.error('Error reloading user data:', err);
    }
  }, [user, currentChain]);

  const handleTransfer = useCallback(async (e) => {
    e.preventDefault();
    setIsTransferring(true);
    setCompletedTransaction(null);

    try {
      if (!user || !user.session) {
        throw new Error('User not authenticated');
      }

      const action = {
        account: token.contract, // Usamos el contrato específico del token
        name: 'transfer',
        authorization: [{ actor: user.name, permission: 'active' }],
        data: {
          from: user.name,
          to,
          quantity: `${formattedQuantity} ${token.symbol}`,
          memo
        }
      };

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNotification({ message: 'Transfer successful!', type: 'success' });
      
      setCompletedTransaction({
        from: user.name,
        to,
        quantity: `${formattedQuantity} ${token.symbol}`,
        memo,
        transactionId: result.transactionId
      });

      // Aquí metemos la recarga de datos del usuario
      await reloadUserData();

      setTo('');
      setQuantity('');
      setMemo('');
    } catch (err) {
      console.error('Transfer error:', err);
      setNotification({ message: err.message || 'An error occurred during the transfer', type: 'error' });
    } finally {
      setIsTransferring(false);
    }
  }, [user, to, formattedQuantity, token, memo]);

  const renderTokenSelector = () => (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">Token</label>
      <div className="relative">
        <select
          value={`${token.contract}-${token.symbol}`}
          onChange={(e) => {
            const [contract, symbol] = e.target.value.split('-');
            const selectedToken = availableTokens.find(t => t.contract === contract && t.symbol === symbol);
            setToken({ 
              contract, 
              symbol, 
              precision: selectedToken.precision 
            });
          }}
          className="w-full bg-white border border-gray-300 rounded-lg px-4 py-2 text-left focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
        >
          {availableTokens.map((t) => (
            <option key={`${t.contract}-${t.symbol}`} value={`${t.contract}-${t.symbol}`}>
              {t.symbol} ({formatNumber(t.amount, t.precision)})
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const renderUserTokens = () => (
    <div className="mt-6">
      <h3 className="text-lg font-semibold mb-2">Your Tokens</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {availableTokens.map((token, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow">
            <p className="font-semibold">{token.symbol}</p>
            <p className="text-sm text-gray-600">{formatNumber(token.amount, token.precision)}</p>
            <p className="text-xs text-gray-500 truncate">{token.contract}</p>
          </div>
        ))}
      </div>
    </div>
  );


  const EasterEgg = () => {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-bold mb-4 text-blue-600">¡Aupa Real!</h2>
          <svg viewBox="0 0 200 300" width="200" height="300" xmlns="http://www.w3.org/2000/svg">
            {/* Cabeza */}
            <circle cx="100" cy="50" r="40" fill="#FFD700" />
            
            {/* Cuerpo */}
            <path d="M60 90 Q100 120 140 90 Q100 180 60 90" fill="#1E90FF" />
            
            {/* Brazos */}
            <path d="M60 100 Q40 120 30 150" stroke="#FFD700" strokeWidth="10" fill="none" />
            <path d="M140 100 Q160 120 170 150" stroke="#FFD700" strokeWidth="10" fill="none" />
            
            {/* Piernas */}
            <path d="M80 180 L70 280" stroke="#FFD700" strokeWidth="15" />
            <path d="M120 180 L130 280" stroke="#FFD700" strokeWidth="15" />
            
            {/* Detalles de la cara */}
            <circle cx="85" cy="40" r="5" fill="#000" />
            <circle cx="115" cy="40" r="5" fill="#000" />
            <path d="M90 60 Q100 70 110 60" stroke="#000" strokeWidth="3" fill="none" />
          </svg>
          <p className="text-xl mt-4">¡Esta sí que es una buena delantera!</p>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-6xl mx-auto">
        {user && userAccountInfo && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <AccountSummary accountInfo={userAccountInfo.account} />
            <ResourcesSection accountInfo={userAccountInfo} />
          </div>
        )}

        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Transfer Tokens</h1>

        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
          <div className="bg-white p-6 rounded-2xl">
            <form onSubmit={handleTransfer} className="space-y-6">
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">From</label>
                  <input
                    type="text"
                    value={user ? user.name : ''}
                    disabled
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                  />
                </div>
                <div className="relative">
                  <label className="block text-sm font-medium text-gray-700 mb-1">To</label>
                  <div className="relative">
                    <input
                      ref={toInputRef}
                      type="text"
                      value={to}
                      onChange={handleToChange}
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      placeholder="Enter account name"
                      required
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  </div>
                  {isSearching && (
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-purple-500"></div>
                    </div>
                  )}
                  {showSuggestions && suggestions.length > 0 && (
                    <ul ref={suggestionsRef} className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-60 overflow-y-auto">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}
                  {searchError && (
                    <p className="mt-1 text-sm text-red-600">
                      <AlertCircle className="inline-block mr-1" size={16} />
                      {searchError}
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Quantity</label>
                <div className="relative">
                  <input
                    type="text"
                    value={quantity}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value) || value === '') {
                        setQuantity(value);
                      }
                    }}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder="0.00000000"
                    required
                  />
                  <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  Formatted: {formattedQuantity} {token.symbol}
                </p>
              </div>
              {renderTokenSelector()}
            </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Memo (optional)</label>
                <textarea
                  value={memo}
                  onChange={(e) => setMemo(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="Enter memo"
                  rows="3"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center disabled:opacity-50"
                  disabled={isTransferring}
                >
                  <Send size={18} className="mr-2" />
                  {isTransferring ? 'Transferring...' : 'Transfer'}
                </button>
              </div>
            </form>
          </div>
        </div>

        

        {completedTransaction && (
          <div className="mt-8 bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 ease-in-out transform hover:shadow-xl">
            <div className="bg-gradient-to-r from-purple-500 to-indigo-600 px-6 py-4">
              <h2 className="text-xl font-semibold text-white">Transaction Completed</h2>
            </div>
            <div className="p-6 space-y-4">
              <div className="flex items-center space-x-4">
                <User className="text-gray-400" size={24} />
                <div>
                  <p className="text-sm text-gray-500">From</p>
                  <p className="font-medium">{completedTransaction.from}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <ArrowRight className="text-gray-400" size={24} />
                <div>
                  <p className="text-sm text-gray-500">To</p>
                  <p className="font-medium">{completedTransaction.to}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <Clipboard className="text-gray-400" size={24} />
                <div>
                  <p className="text-sm text-gray-500">Amount</p>
                  <p className="font-medium text-green-600">{completedTransaction.quantity}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <MessageSquare className="text-gray-400" size={24} />
                <div>
                  <p className="text-sm text-gray-500">Memo</p>
                  <p className="font-medium">{completedTransaction.memo || 'N/A'}</p>
                </div>
              </div>
              <div className="pt-4 border-t border-gray-200">
                <p className="text-sm text-gray-500 mb-2">Transaction ID</p>
                <p className="font-mono text-sm break-all">{completedTransaction.transactionId}</p>
              </div>
            </div>
            <div className="px-6 py-4 bg-gray-50">
              <a 
                href={`${blockExplorerUrl}/transaction/${completedTransaction.transactionId}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors duration-300 ease-in-out"
              >
                View in Block Explorer
                <ExternalLink size={18} className="ml-2" />
              </a>
            </div>
          </div>
        )}
      </div>
      
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
}

export default TransferTokens;