import React, { useState } from 'react';
import { ChevronRight, ChevronDown, Key, Edit, Trash, Plus, Link as LinkIcon } from 'lucide-react';


const PermissionManager = ({ permissions, userPermission, onUpdatePermission }) => {
  //const [expandedPermissions, setExpandedPermissions] = useState({});

  const [expandedPermissions, setExpandedPermissions] = useState(() => {
    const initialState = {};
    permissions.forEach(permission => {
      initialState[permission.perm_name] = true;
    });
    return initialState;
  });





  const [editingPermission, setEditingPermission] = useState(null);
  const [isAddingPermission, setIsAddingPermission] = useState(false);
  const [newPermission, setNewPermission] = useState({
    perm_name: '',
    parent: '',
    required_auth: {
      threshold: 1,
      keys: [],
      accounts: [],
      waits: []
    }
  });

  const togglePermission = (permName) => {
    setExpandedPermissions(prev => ({
      ...prev,
      [permName]: !prev[permName]
    }));
  };

  const canEditPermission = (permission) => {
    if (!userPermission) return false;
    if (userPermission === 'owner') return true;
    if (userPermission === permission.perm_name) return true;
    return isAncestorPermission(userPermission, permission.perm_name);
  };

  const isAncestorPermission = (ancestorPerm, targetPerm) => {
    let currentPerm = permissions.find(p => p.perm_name === targetPerm);
    while (currentPerm && currentPerm.parent) {
      if (currentPerm.parent === ancestorPerm) return true;
      currentPerm = permissions.find(p => p.perm_name === currentPerm.parent);
    }
    return false;
  };

  const handleDeletePermission = (permission) => {
    if (window.confirm(`Are you sure you want to delete the permission "${permission.perm_name}"?`)) {
      onUpdatePermission({
        ...permission,
        delete: true
      });
    }
  };

  const renderPermissionTree = (permission, depth = 0) => {
    const hasChildren = permissions.some(p => p.parent === permission.perm_name);
    
    return (
      <div key={permission.perm_name} className="mb-2" style={{ marginLeft: `${depth * 20}px` }}>
        <div className="flex items-center">
          {hasChildren && (
            <button 
              onClick={() => togglePermission(permission.perm_name)}
              className="mr-2 focus:outline-none"
            >
              {expandedPermissions[permission.perm_name] ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
            </button>
          )}
          <span className="font-medium">{permission.perm_name} ({permission.required_auth.threshold})</span>
          {canEditPermission(permission) && (
            <>
              <button 
                onClick={() => setEditingPermission(permission)}
                className="ml-auto mr-2 text-blue-600 hover:text-blue-800"
              >
                <Edit size={16} />
              </button>
              {permission.perm_name !== 'owner' && (
                <button 
                  onClick={() => handleDeletePermission(permission)}
                  className="mr-2 text-red-600 hover:text-red-800"
                >
                  <Trash size={16} />
                </button>
              )}
            </>
          )}
        </div>
        <div className="mt-1 ml-6">
          {permission.required_auth.keys.map((key, index) => (
            <div key={index} className="flex items-center text-sm text-gray-600">
              <Key className="mr-2" size={12} />
              <span>+{key.weight} {key.key}</span>
            </div>
          ))}
          {permission.required_auth.accounts.map((account, index) => (
            <div key={index} className="text-sm text-blue-600">
              {account.permission.actor}@{account.permission.permission}
            </div>
          ))}
          {permission.linked_actions && permission.linked_actions.length > 0 && (
            <div className="mt-2">
              <p className="text-sm font-medium text-gray-700">Linked actions:</p>
              {permission.linked_actions.map((link, index) => (
                <div key={index} className="flex items-center text-sm text-green-600">
                  <LinkIcon className="mr-2" size={12} />
                  <span>{link.account}::{link.action}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {expandedPermissions[permission.perm_name] && permissions
          .filter(p => p.parent === permission.perm_name)
          .map(childPerm => renderPermissionTree(childPerm, depth + 1))}
      </div>
    );
  };

  const renderEditForm = () => {
    if (!editingPermission) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Edit Permission</h3>
          <form onSubmit={(e) => {
            e.preventDefault();
            onUpdatePermission(editingPermission);
            setEditingPermission(null);
          }}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Permission Name</label>
              <input 
                type="text" 
                value={editingPermission.perm_name} 
                disabled 
                className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Threshold</label>
              <input 
                type="number" 
                value={editingPermission.required_auth.threshold}
                onChange={(e) => setEditingPermission({
                  ...editingPermission,
                  required_auth: {
                    ...editingPermission.required_auth,
                    threshold: parseInt(e.target.value)
                  }
                })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Keys</label>
              {editingPermission.required_auth.keys.map((key, index) => (
                <div key={index} className="flex items-center mt-2">
                  <input 
                    type="text" 
                    value={key.key}
                    onChange={(e) => {
                      const newKeys = [...editingPermission.required_auth.keys];
                      newKeys[index].key = e.target.value;
                      setEditingPermission({
                        ...editingPermission,
                        required_auth: {
                          ...editingPermission.required_auth,
                          keys: newKeys
                        }
                      });
                    }}
                    className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  <input 
                    type="number" 
                    value={key.weight}
                    onChange={(e) => {
                      const newKeys = [...editingPermission.required_auth.keys];
                      newKeys[index].weight = parseInt(e.target.value);
                      setEditingPermission({
                        ...editingPermission,
                        required_auth: {
                          ...editingPermission.required_auth,
                          keys: newKeys
                        }
                      });
                    }}
                    className="w-20 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  <button 
                    type="button"
                    onClick={() => {
                      const newKeys = editingPermission.required_auth.keys.filter((_, i) => i !== index);
                      setEditingPermission({
                        ...editingPermission,
                        required_auth: {
                          ...editingPermission.required_auth,
                          keys: newKeys
                        }
                      });
                    }}
                    className="ml-2 text-red-600 hover:text-red-800"
                  >
                    <Trash size={16} />
                  </button>
                </div>
              ))}
              <button 
                type="button"
                onClick={() => {
                  setEditingPermission({
                    ...editingPermission,
                    required_auth: {
                      ...editingPermission.required_auth,
                      keys: [...editingPermission.required_auth.keys, { key: '', weight: 1 }]
                    }
                  });
                }}
                className="mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Key
              </button>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="submit"
                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                Update Permission
              </button>
              <button
                type="button"
                className="mt-3 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                onClick={() => setEditingPermission(null)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const renderAddForm = () => {
    if (!isAddingPermission) return null;

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Add New Permission</h3>
          <form onSubmit={(e) => {
            e.preventDefault();
            onUpdatePermission(newPermission);
            setIsAddingPermission(false);
            setNewPermission({
              perm_name: '',
              parent: '',
              required_auth: {
                threshold: 1,
                keys: [],
                accounts: [],
                waits: []
              }
            });
          }}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Permission Name</label>
              <input 
                type="text" 
                value={newPermission.perm_name}
                onChange={(e) => setNewPermission({...newPermission, perm_name: e.target.value})}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Parent Permission</label>
              <select
                value={newPermission.parent}
                onChange={(e) => setNewPermission({...newPermission, parent: e.target.value})}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                required
              >
                <option value="">Select parent permission</option>
                {permissions.map(perm => (
                  <option key={perm.perm_name} value={perm.perm_name}>{perm.perm_name}</option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Threshold</label>
              <input 
                type="number" 
                value={newPermission.required_auth.threshold}
                onChange={(e) => setNewPermission({
                  ...newPermission,
                  required_auth: {
                    ...newPermission.required_auth,
                    threshold: parseInt(e.target.value)
                  }
                })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Keys</label>
              {newPermission.required_auth.keys.map((key, index) => (
                <div key={index} className="flex items-center mt-2">
                  <input 
                    type="text" 
                    value={key.key}
                    onChange={(e) => {
                      const newKeys = [...newPermission.required_auth.keys];
                      newKeys[index].key = e.target.value;
                      setNewPermission({
                        ...newPermission,
                        required_auth: {
                          ...newPermission.required_auth,
                          keys: newKeys
                        }
                      });
                    }}
                    placeholder="Public Key"
                    className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  <input 
                    type="number" 
                    value={key.weight}
                    onChange={(e) => {
                      const newKeys = [...newPermission.required_auth.keys];
                      newKeys[index].weight = parseInt(e.target.value);
                      setNewPermission({
                        ...newPermission,
                        required_auth: {
                          ...newPermission.required_auth,
                          keys: newKeys
                        }
                      });
                    }}
                    placeholder="Weight"
                    className="w-20 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  <button 
                    type="button"
                    onClick={() => {
                      const newKeys = newPermission.required_auth.keys.filter((_, i) => i !== index);
                      setNewPermission({
                          ...newPermission,
                        required_auth: {
                          ...newPermission.required_auth,
                          keys: newKeys
                        }
                      });
                    }}
                    className="ml-2 text-red-600 hover:text-red-800"
                  >
                    <Trash size={16} />
                  </button>
                </div>
              ))}
              <button 
                type="button"
                onClick={() => {
                  setNewPermission({
                    ...newPermission,
                    required_auth: {
                      ...newPermission.required_auth,
                      keys: [...newPermission.required_auth.keys, { key: '', weight: 1 }]
                    }
                  });
                }}
                className="mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Key
              </button>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="submit"
                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                Create Permission
              </button>
              <button
                type="button"
                className="mt-3 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                onClick={() => setIsAddingPermission(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div>
      {permissions.filter(p => !p.parent).map(rootPerm => renderPermissionTree(rootPerm))}
      {renderEditForm()}
      {renderAddForm()}
      <button 
        onClick={() => setIsAddingPermission(true)}
        className="mt-4 flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <Plus size={16} className="mr-2" />
        Add New Permission
      </button>
    </div>
  );
};

export default PermissionManager;