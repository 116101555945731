import React, { useState, useRef, useEffect } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import chainConfig from '../config/chainConfig';
import WalletConnector from './WalletConnector';
import AccountSearch from './AccountSearch';
import { Menu, ChevronDown, User, X } from 'lucide-react';

const chainFamilies = [
  ['telos-mainnet', 'telos-testnet'],
  ['wax-mainnet', 'wax-testnet']
];

function Header({ setSidebarOpen, className = '' }) {
  const { currentChain, setCurrentChain, user, isAuthenticated } = useChainContext();
  const [isNetworkDropdownOpen, setIsNetworkDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsNetworkDropdownOpen(false);
      }
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const currentNetworkLogo = chainConfig[currentChain]?.logo || null;
  const currentNetworkName = chainConfig[currentChain]?.name || 'Select Network';

  const handleChainChange = (chainId) => {
    if (isAuthenticated) {
      const confirmChange = window.confirm("Changing the network will log you out. Are you sure you want to continue?");
      if (!confirmChange) return;
    }

    const subdomain = chainConfig[chainId].subdomain;
    const newUrl = `https://${subdomain}.chainscout.io${window.location.pathname}`;
    window.location.href = newUrl;

    setCurrentChain(chainId);
    setIsNetworkDropdownOpen(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <header className={`bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg ${className}`}>
      <div className="container mx-auto px-4 py-3">
        {/* Desktop Layout */}
        <div className="hidden lg:flex justify-between items-center">
          <div className="flex items-center space-x-4 flex-grow">
            <AccountSearch />
          </div>
          
          <div className="flex items-center space-x-4">
            <NetworkSelector
              currentNetworkLogo={currentNetworkLogo}
              currentNetworkName={currentNetworkName}
              isNetworkDropdownOpen={isNetworkDropdownOpen}
              setIsNetworkDropdownOpen={setIsNetworkDropdownOpen}
              dropdownRef={dropdownRef}
              chainFamilies={chainFamilies}
              handleChainChange={handleChainChange}
              currentChain={currentChain}
            />
            <WalletConnector />
            {isAuthenticated && user && (
              <div className="flex items-center space-x-2">
                <User size={20} />
                <span className="text-sm font-medium">{user.name}</span>
              </div>
            )}
          </div>
        </div>

        {/* Mobile Layout */}
        <div className="lg:hidden">
          <div className="flex justify-between items-center">
            <button
              className="text-white focus:outline-none"
              onClick={() => setSidebarOpen(true)}
              aria-label="Open sidebar"
            >
              <Menu size={24} />
            </button>

            <div className="flex-1 mx-4">
              <AccountSearch />
            </div>

            <button
              className="text-white focus:outline-none"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              aria-label="Open menu"
            >
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>

          {/* Mobile Menu */}
          {isMobileMenuOpen && (
            <div 
              ref={mobileMenuRef}
              className="absolute left-0 right-0 mt-2 bg-white shadow-lg z-50 mx-4 rounded-lg"
            >
              <div className="p-4 space-y-4">
                <div className="border-b border-gray-200 pb-4">
                  <span className="text-gray-700 font-medium">Select Network</span>
                  <div className="mt-2 space-y-2">
                    {chainFamilies.map((family, familyIndex) => (
                      <div key={familyIndex} className="space-y-2">
                        {family.map((chainId) => (
                          <button
                            key={chainId}
                            className={`${
                              chainId === currentChain ? 'bg-indigo-100 text-indigo-900' : 'text-gray-700'
                            } flex items-center space-x-2 w-full px-3 py-2 rounded-md hover:bg-indigo-50`}
                            onClick={() => handleChainChange(chainId)}
                          >
                            <img src={chainConfig[chainId].logo} alt="" className="w-5 h-5" />
                            <span className="text-sm">{chainConfig[chainId].name}</span>
                          </button>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                
                <div className="py-2">
                  <WalletConnector />
                </div>

                {isAuthenticated && user && (
                  <div className="flex items-center space-x-2 py-2 text-gray-700">
                    <User size={20} />
                    <span className="text-sm font-medium">{user.name}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

// Network Selector Component
const NetworkSelector = ({
  currentNetworkLogo,
  currentNetworkName,
  isNetworkDropdownOpen,
  setIsNetworkDropdownOpen,
  dropdownRef,
  chainFamilies,
  handleChainChange,
  currentChain
}) => (
  <div className="relative" ref={dropdownRef}>
    <button
      className="flex items-center w-48 px-4 py-2 text-sm font-medium text-white bg-opacity-20 bg-white border border-white border-opacity-30 rounded-md shadow-sm hover:bg-opacity-30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
      onClick={() => setIsNetworkDropdownOpen(!isNetworkDropdownOpen)}
      aria-haspopup="true"
      aria-expanded={isNetworkDropdownOpen}
    >
      {currentNetworkLogo && (
        <img src={currentNetworkLogo} alt="" className="w-5 h-5 mr-2 inline-block" />
      )}
      <span className="flex-grow text-left">{currentNetworkName}</span>
      <ChevronDown size={16} />
    </button>

    {isNetworkDropdownOpen && (
      <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
        <div className="py-1" role="menu" aria-orientation="vertical">
          {chainFamilies.map((family, familyIndex) => (
            <React.Fragment key={familyIndex}>
              {familyIndex > 0 && <hr className="my-1 border-gray-200" />}
              {family.map((chainId) => (
                <button
                  key={chainId}
                  className={`${
                    chainId === currentChain ? 'bg-indigo-100 text-indigo-900' : 'text-gray-700'
                  } block px-4 py-2 text-sm hover:bg-indigo-50 w-full text-left flex items-center`}
                  onClick={() => handleChainChange(chainId)}
                  role="menuitem"
                >
                  <img src={chainConfig[chainId].logo} alt="" className="w-5 h-5 mr-2 inline-block" />
                  <span>{chainConfig[chainId].name}</span>
                </button>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    )}
  </div>
);

export default Header;