import React, { useState, useEffect, useMemo } from 'react';
import { useChainContext } from '../../contexts/ChainContext';
import BlockchainService from '../../services/blockchainService';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Loader2, AlertCircle, Wallet, Coins } from 'lucide-react';

export default function Rex() {
  const [holders, setHolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentChain } = useChainContext();
  const blockchainService = useMemo(() => new BlockchainService(currentChain), [currentChain]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalHolders, setTotalHolders] = useState(0);

  useEffect(() => {
    const fetchHolders = async () => {
      setLoading(true);
      try {
        const response = await blockchainService.fetchFromChain('/get_rex_holders');
        setHolders(response.holders);
        setTotalHolders(response.holders.length);
      } catch (err) {
        console.error('Error fetching REX holders:', err);
        setError('Failed to fetch REX holders data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchHolders();
  }, [blockchainService]);

  const totalPages = Math.ceil(totalHolders / itemsPerPage);
  const currentHolders = holders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (loading) {
    return (
      <div className="text-center py-8">
        <Loader2 className="animate-spin mx-auto mb-2" />
        <span className="text-gray-600">Loading REX holders...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-500">
        <AlertCircle className="mx-auto mb-2" size={24} />
        <span>{error}</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-8xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Top REX Holders</h1>
        
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg mb-6">
          <div className="bg-white p-6 rounded-2xl">
            <div className="mb-4 flex justify-between items-center">
              <div className="text-gray-600">

              </div>
              <select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                <option value={20}>20 per page</option>
                <option value={50}>50 per page</option>
                <option value={100}>100 per page</option>
              </select>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Rank
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Account
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Total REX
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentHolders.map((holder, index) => (
                    <tr key={holder.account} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        #{(currentPage - 1) * itemsPerPage + index + 1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Link 
                          to={`/account-detail/${holder.account}`} 
                          className="text-blue-600 hover:text-blue-800 flex items-center group"
                        >
                          <Wallet size={16} className="mr-2 flex-shrink-0" />
                          <span className="relative">
                            {holder.account}
                            <span className="absolute inset-x-0 bottom-0 h-0.5 bg-blue-800 transform scale-x-0 origin-center transition-transform duration-300 ease-out group-hover:scale-x-100"></span>
                          </span>
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Coins size={16} className="mr-2 text-indigo-500" />
                          <span className="text-gray-900">{Number(holder.balance).toLocaleString('en-US', { maximumFractionDigits: 0 })} TLOS</span>
                          
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-6 flex items-center justify-between border-t border-gray-200 pt-4">
              <div className="flex items-center">
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span>
                  {' '}-{' '}
                  <span className="font-medium">
                    {Math.min(currentPage * itemsPerPage, totalHolders)}
                  </span>
                  {' '}of{' '}
                  <span className="font-medium">{totalHolders}</span>
                  {' '}holders
                </p>
              </div>
              
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronLeft size={16} className="mr-1" />
                  Previous
                </button>
                <span className="px-4 py-2 text-sm text-gray-700">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Next
                  <ChevronRight size={16} className="ml-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}