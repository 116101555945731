import { getChainConfig } from '../config/chainConfig';

class BlockchainService {
  constructor(chainId) {
    this.chainId = chainId;
    this.chainConfig = getChainConfig(chainId);
    //this.apiUrl = 'http://localhost:5000/api'; // Asegúrate de que esto coincida con la URL de tu backend
    //this.apiUrl = 'https://api.chainscout.io/api';
    this.apiUrl = process.env.REACT_APP_API_URL;
  }

  //const API_URL = process.env.REACT_APP_API_URL;


  async fetchFromChain(path, body = null) {
    const url = `${this.apiUrl}/${this.chainId}${path}`;
    const options = {
      method: body ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body ? JSON.stringify(body) : undefined
    };

    try {
      
      const response = await fetch(url, options);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }
      
      return data;
    } catch (error) {
      console.error('Error in fetchFromChain:', error);
      throw new Error(`Error fetching data: ${error.message}`);
    }
  }

  async searchAccounts(searchTerm) {
    return this.fetchFromChain('/search_accounts', { search: searchTerm }, 'POST');
  }

  async getInfo() {
    return this.fetchFromChain('/info');
  }

  getChainFamily() {
    return this.chainConfig.chainFamily;
  }

  getChainName() {
    return this.chainConfig.name;
  }

  getChainSymbol() {
    return this.chainConfig.symbol;
  }

  getBlockExplorerUrl() {
    return this.chainConfig.blockExplorerUrl;
  }
}

export default BlockchainService;