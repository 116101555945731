// src/config/chainConfig.js
import waxLogo from '../assets/wax.webp';
import telosLogo from '../assets/telos.png';

const chainConfig = {
  'wax-mainnet': {
    name: 'WAX',
    chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'wax.greymass.com',
        port: 443,
      },
      {
        protocol: 'https',
        host: 'api.waxsweden.org',
        port: 443,
      }
    ],
    symbol: 'WAX',
    blockExplorerUrl: 'https://waxblock.io',
    chainFamily: 'WAX',
    token: 'WAX',
    logo: waxLogo,
    subdomain: 'wax'
  },
  'wax-testnet': {
    name: 'WAX Testnet',
    chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'testnet.waxsweden.org',
        port: 443,
      }
    ],
    symbol: 'WAX',
    blockExplorerUrl: 'https://testnet.waxblock.io',
    chainFamily: 'WAX',
    token: 'WAX',
    logo: waxLogo,
    subdomain: 'wax-testnet'
  },
  'telos-mainnet': {
    name: 'TELOS',
    chainId: '4667b205c6838ef70ff7988f6e8257e8be0e1284a2f59699054a018f743b1d11',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'mainnet.telos.net',
        port: 443,
      }
    ],
    symbol: 'TLOS',
    blockExplorerUrl: 'https://explorer.telos.net',
    chainFamily: 'TELOS',
    token: 'TLOS',
    logo: telosLogo,
    subdomain: 'telos'
  },
  'telos-testnet': {
    name: 'TELOS Testnet',
    chainId: '1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'testnet.telos.net',
        port: 443,
      }
    ],
    symbol: 'TLOS',
    blockExplorerUrl: 'https://testnet.telos.net/v2/explore',
    chainFamily: 'TELOS',
    token: 'TLOS',
    logo: telosLogo,
    subdomain: 'telos-testnet'
  }/* ,
  'fio-mainnet': {
    name: 'FIO Mainnet',
    chainId: '21dcae42c0182200e93f954a074011f9048a7624c6fe81d3c9541a614a88bd1c',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'fio.greymass.com',
        port: 443,
      }
    ],
    symbol: 'FIO',
    blockExplorerUrl: 'https://fio.bloks.io',
    chainFamily: 'FIO',
    token: 'FIO'
  },
  'fio-testnet': {
    name: 'FIO Testnet',
    chainId: 'b20901380af44ef59c5918439a1f9a41d83669020319a80574b804a5f95cbd7e',
    rpcEndpoints: [
      {
        protocol: 'https',
        host: 'testnet.fioprotocol.io',
        port: 443,
      }
    ],
    symbol: 'FIO',
    blockExplorerUrl: 'https://fio-test.bloks.io',
    chainFamily: 'FIO',
    token: 'FIO'
  } */
};

export const getChainConfig = (chainId) => {
  const config = chainConfig[chainId];
  if (!config) {
    throw new Error(`No configuration found for chain: ${chainId}`);
  }
  return config;
};

export default chainConfig;