import React, { useState, useEffect, useMemo } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import { Link } from 'react-router-dom';
import { FileText, User, Vote, ChevronLeft, ChevronRight, Loader2, AlertCircle } from 'lucide-react';

const MSIG = () => {
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentChain } = useChainContext();
  const blockchainService = useMemo(() => new BlockchainService(currentChain), [currentChain]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalProposals, setTotalProposals] = useState(0);

  useEffect(() => {
    const fetchProposals = async () => {
      setLoading(true);
      try {
        const response = await blockchainService.fetchFromChain(`/get_all_proposals?page=${currentPage}&limit=${itemsPerPage}`);
        setProposals(response.proposals);
        setTotalProposals(response.total.value);
      } catch (err) {
        console.error('Error fetching proposals:', err);
        setError('Failed to fetch proposals. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProposals();
  }, [blockchainService, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(totalProposals / itemsPerPage);

  if (loading) {
    return (
      <div className="text-center py-8">
        <Loader2 className="animate-spin mx-auto mb-2" />
        <span className="text-gray-600">Loading proposals...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-500">
        <AlertCircle className="mx-auto mb-2" size={24} />
        <span>{error}</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-8xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Multisig Transactions</h1>
        
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg mb-6">
          <div className="bg-white p-6 rounded-2xl">
            <div className="mb-4 flex justify-between items-center">
              <div className="text-gray-600">
                Total Proposals: {totalProposals}
              </div>
              <select
  value={itemsPerPage}
  onChange={(e) => setItemsPerPage(Number(e.target.value))}
  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
>
  <option value={20}>20 per page</option>
  <option value={50}>50 per page</option>
  <option value={100}>100 per page</option>
</select>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Proposal Name
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Approval Status
                    </th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Proposer
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {proposals.map((proposal) => (
                    <tr key={proposal.proposal_name} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Link 
                          to={`/proposal/${proposal.proposer}/${proposal.proposal_name}`} 
                          className="text-blue-600 hover:text-blue-800 flex items-center group"
                        >
                          <FileText size={16} className="mr-2 flex-shrink-0" />
                          <span className="relative">
                            {proposal.proposal_name}
                            <span className="absolute inset-x-0 bottom-0 h-0.5 bg-blue-800 transform scale-x-0 origin-center transition-transform duration-300 ease-out group-hover:scale-x-100"></span>
                          </span>
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Vote size={16} className="mr-2 text-indigo-500" />
                          <span className="bg-indigo-100 text-indigo-800 px-2 py-1 rounded-full text-xs font-medium">
                            {proposal.provided_approvals.length}/{proposal.provided_approvals.length + proposal.requested_approvals.length}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <User size={16} className="mr-2 text-gray-400" />
                          <span className="text-gray-900">{proposal.proposer}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-6 flex items-center justify-between border-t border-gray-200 pt-4">
              <div className="flex items-center">
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span>
                  {' '}-{' '}
                  <span className="font-medium">
                    {Math.min(currentPage * itemsPerPage, totalProposals)}
                  </span>
                  {' '}of{' '}
                  <span className="font-medium">{totalProposals}</span>
                  {' '}proposals
                </p>
              </div>
              
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronLeft size={16} className="mr-1" />
                  Previous
                </button>
                <span className="px-4 py-2 text-sm text-gray-700">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Next
                  <ChevronRight size={16} className="ml-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSIG;