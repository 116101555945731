import React, { useState, useEffect, useCallback } from 'react';
import { Cpu, HardDrive, RefreshCw, Plus, Minus } from 'lucide-react';
import AddCpuNet from './resources/AddCpuNet';
import RemoveCpuNet from './resources/RemoveCpuNet';
import RefundCpuNet from './resources/RefundCpuNet';
import BuyRam from './resources/BuyRam';
import SellRam from './resources/SellRam';
import BlockchainService from '../../services/blockchainService';
import { useChainContext } from '../../contexts/ChainContext';
import AccountSummary from '../../components/AccountSummary';
import ResourcesSection from '../../components/ResourcesSection';


const ActionButton = ({ icon: Icon, label, onClick, isActive }) => (
  <button
    onClick={onClick}
    className={`flex items-center justify-center px-4 py-2 border ${
      isActive
        ? 'bg-indigo-600 text-white'
        : 'bg-white text-gray-700 hover:bg-gray-50'
    } rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
  >
    <Icon className="w-5 h-5 mr-2" />
    {label}
  </button>
);

const Resources = () => {
  const { user, currentChain } = useChainContext();
  const [activeAction, setActiveAction] = useState('add');
  const [userAccountInfo, setUserAccountInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAccountInfo = useCallback(async () => {
    if (!user || !user.name) {
      setUserAccountInfo(null);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      
      const blockchainService = new BlockchainService(currentChain);
      const info = await blockchainService.fetchFromChain(`/get_account/${user.name}`);
      
      setUserAccountInfo(info);
    } catch (err) {
      console.error('Error fetching user account info:', err);
      setError('Failed to fetch account info. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [user, currentChain]);

  useEffect(() => {
    fetchAccountInfo();
  }, [fetchAccountInfo, user, currentChain]);

  const handleTransactionSuccess = useCallback(() => {

    
    // Esperar 2 segundos antes de actualizar la información
    setTimeout(() => {

      fetchAccountInfo();
    }, 2000); // 2000 milisegundos = 2 segundos
  }, [fetchAccountInfo]);

  const renderActionContent = (userAccountInfo) => {




    switch (activeAction) {
      case 'add':
        return <AddCpuNet onTransactionSuccess={handleTransactionSuccess} />;
      case 'remove':
        return <RemoveCpuNet onTransactionSuccess={handleTransactionSuccess} />;
      case 'refund':
        return <RefundCpuNet refund={userAccountInfo?.account.refund_request} onTransactionSuccess={handleTransactionSuccess} />;
      case 'buyRam':
        return <BuyRam onTransactionSuccess={handleTransactionSuccess} />;
      case 'sellRam':
        return <SellRam onTransactionSuccess={handleTransactionSuccess} />;
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-7xl">
        <div className="max-w-6xl mx-auto">
      

      {user && userAccountInfo && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <AccountSummary accountInfo={userAccountInfo.account} />
          <ResourcesSection accountInfo={userAccountInfo} />
        </div>
      )}
      
      <div className="flex flex-wrap justify-center gap-2 mb-6">
          <ActionButton
            icon={Plus}
            label="Add CPU/NET"
            onClick={() => setActiveAction('add')}
            isActive={activeAction === 'add'}
          />
          <ActionButton
            icon={Minus}
            label="Remove CPU/NET"
            onClick={() => setActiveAction('remove')}
            isActive={activeAction === 'remove'}
          />
          <ActionButton
            icon={RefreshCw}
            label="Refund CPU/NET"
            onClick={() => setActiveAction('refund')}
            isActive={activeAction === 'refund'}
          />
          <ActionButton
            icon={Plus}
            label="Buy RAM"
            onClick={() => setActiveAction('buyRam')}
            isActive={activeAction === 'buyRam'}
          />
          <ActionButton
            icon={Minus}
            label="Sell RAM"
            onClick={() => setActiveAction('sellRam')}
            isActive={activeAction === 'sellRam'}
          />
        </div>

        {renderActionContent(userAccountInfo)}
      </div>
    </div>
  );
};

export default Resources;

