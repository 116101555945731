import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp, ArrowUpRight, ArrowDownRight, Lock, Unlock, Vote, User, Database, HardDrive, ArrowRight } from 'lucide-react';

const ExpandableData = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const contentRef = useRef(null);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const renderValue = (value, depth = 0) => {
    if (typeof value === 'object' && value !== null) {
      return Object.entries(value).map(([key, val]) => (
        <div key={key} style={{ marginLeft: `${depth * 20}px` }}>
          <span className="font-medium">{key}:</span> {renderValue(val, depth + 1)}
        </div>
      ));
    }
    return <span>{JSON.stringify(value)}</span>;
  };

  useEffect(() => {
    if (contentRef.current) {
      setIsExpandable(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    }
  }, [data]);

  const formattedContent = renderValue(data);

  return (
    <div className="relative">
      <div 
        ref={contentRef}
        className={`whitespace-pre-wrap text-sm ${isExpanded ? '' : 'max-h-24 overflow-hidden'}`}
      >
        {formattedContent}
      </div>
      {isExpandable && (
        <button
          onClick={toggleExpand}
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 bg-white border rounded-full p-1"
        >
          {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </button>
      )}
    </div>
  );
};

const ActionRenderer = ({ action, accountName }) => {
  const { contract = action.account, action: actionName = action.name, data } = action;

  const renderSpecialCase = () => {
    switch (`${contract}::${actionName}`) {
      case 'eosio.token::transfer':
        return (
          <div className="flex items-center space-x-2">
            <span className="font-medium">{data.from}</span>
            {
               data.from === (accountName || data.from) 
                      ? <ArrowUpRight className="text-red-500" />
                      : <ArrowDownRight className="text-green-500" />
            }
            <span className="font-medium">{data.to}</span>
            <span className="font-bold">{data.quantity}</span>
            {data.memo && (
              <span className="text-gray-500 italic">"{data.memo}"</span>
            )}
          </div>
        );
      
      case 'eosio::delegatebw':
      case 'eosio::undelegatebw':
        const isDelegate = actionName === 'delegatebw';
        return (
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <span className="font-medium">{data.from}</span>
              {isDelegate ? <Lock className="text-blue-500" /> : <Unlock className="text-green-500" />}
              <span className="font-medium">{data.receiver}</span>
            </div>
            <div>Stake Net: {data.stake_net_quantity}</div>
            <div>Stake CPU: {data.stake_cpu_quantity}</div>
          </div>
        );
      
      case 'eosio::voteproducer':
        return (
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <Vote className="text-purple-500" />
              <span className="font-medium">{data.voter}</span>
            </div>
            {data.proxy && <div>Proxy: {data.proxy}</div>}
            {data.producers && (
              <div>
                Producers: 
                <div className="ml-4">
                  {data.producers.join(', ')}
                </div>
              </div>
            )}
          </div>
        );
      
      case 'eosio::buyram':
      case 'eosio::buyrambytes':
        return (
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <Database className="text-yellow-500" />
              <span className="font-medium">{data.payer}</span>
              <ArrowRight className="text-gray-500" />
              <span className="font-medium">{data.receiver}</span>
            </div>
            <div>Amount: {data.quant || `${data.bytes} bytes`}</div>
          </div>
        );
      
      case 'eosio::sellram':
        return (
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <HardDrive className="text-red-500" />
              <span className="font-medium">{data.account}</span>
            </div>
            <div>Bytes: {data.bytes}</div>
          </div>
        );
      
      case 'eosio::newaccount':
        return (
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <User className="text-green-500" />
              <span className="font-medium">{data.creator}</span>
              <ArrowRight className="text-gray-500" />
              <span className="font-medium">{data.name}</span>
            </div>
            <div className="text-sm text-gray-600">New account created</div>
          </div>
        );
      
      case 'eosio::updateauth':
        return (
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <Lock className="text-orange-500" />
              <span className="font-medium">{data.account}</span>
            </div>
            <div>Permission: {data.permission}</div>
            <div>Parent: {data.parent}</div>
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <td className="px-6 py-4 text-sm text-gray-500">
      {renderSpecialCase() || <ExpandableData data={data} />}
    </td>
  );
};

export default ActionRenderer;