import React from 'react';

function Home() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Welcome</h2>
        <p className="text-gray-600">Explore blockchain data and manage your assets across multiple EOSIO/Antelope chains.</p>
      </div>
      {/* Add more card components for other dashboard elements */}
    </div>
  );
}

export default Home;