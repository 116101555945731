import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChainContext } from '../contexts/ChainContext';
import { Search } from 'lucide-react';
import BlockchainService from '../services/blockchainService';
import { debounce } from 'lodash';

const AccountSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const { currentChain } = useChainContext();
  const suggestionsRef = useRef(null);
  const blockchainService = useRef(new BlockchainService(currentChain));
  const navigate = useNavigate();

  useEffect(() => {
    blockchainService.current = new BlockchainService(currentChain);
  }, [currentChain]);

  const debouncedSearch = useRef(
    debounce(async (search) => {
      if (search.length >= 3) {
        try {
          
          const response = await blockchainService.current.searchAccounts(search);
          
          const accounts = response.rows.map(row => row.scope);
          setSuggestions(accounts);
          
          // New: Auto-redirect if there's only one result
          if (accounts.length === 1) {
            navigate(`/account-detail/${accounts[0]}`);
            setSearchTerm('');
            setSuggestions([]);
          }
        } catch (error) {
          console.error('Error searching accounts:', error);
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    }, 300)
  ).current;

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/account-detail/${searchTerm.trim()}`);
      setSearchTerm('');
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    navigate(`/account-detail/${suggestion}`);
    setSearchTerm('');
    setSuggestions([]);
  };

  return (
    <form onSubmit={handleSearch} className="flex-1 max-w-2xl relative">
      <div className="relative">
        <input
          type="text"
          placeholder={`Search accounts on ${blockchainService.current.getChainName()}...`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 text-gray-700 bg-white border rounded-full focus:border-purple-400 focus:outline-none focus:ring focus:ring-purple-300 focus:ring-opacity-40"
        />
        <button
          type="submit"
          className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-700"
          aria-label="Search"
        >
          <Search size={20} />
        </button>
      </div>
      {suggestions.length > 1 && (
        <ul ref={suggestionsRef} className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </form>
  );
};

export default AccountSearch;