import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { UALProvider, UALContext } from 'ual-reactjs-renderer';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { useChainContext } from '../contexts/ChainContext';
import { getChainConfig } from '../config/chainConfig';
import { useNavigate } from 'react-router-dom';

// Fix for Wax Buffer issue
import { Buffer } from 'buffer';

if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
}

const WalletConnector = () => {
  const { currentChain } = useChainContext();

  const ualConfig = useMemo(() => {
    const chain = getChainConfig(currentChain);
    const appName = "ChainScout";

    const myChain = {
      chainId: chain.chainId,
      rpcEndpoints: chain.rpcEndpoints
    };

    const anchor = new Anchor([myChain], { appName });
    const authenticators = [anchor];

        // Agregar el authenticator Wax solo si la blockchain es wax-mainnet
        if (currentChain === 'wax-mainnet') {
          const wax = new Wax([myChain], { appName });
          authenticators.push(wax);
        }

    return {
      chains: [myChain],
      appName,
      authenticators
    };
  }, [currentChain]);

  return (
    <UALProvider key={currentChain} {...ualConfig}>
      <UALConsumer />
    </UALProvider>
  );
};

const UALConsumer = () => {
  const ual = React.useContext(UALContext);
  const { login, logout, isAuthenticated, currentChain } = useChainContext();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkActiveUser = async () => {
      if (ual.activeUser) {
        try {
          const accountName = await ual.activeUser.getAccountName();
          
          login({
            name: accountName,
            session: ual.activeUser
          });

          // Redirigir al componente AccountDetail con el nombre de usuario
          navigate(`/account-detail/${accountName}`);
        } catch (error) {
          console.error('Error retrieving active user:', error);
          logout();
        }
      } else {
        
        logout();
      }
    };

    checkActiveUser();
  }, [ual.activeUser, currentChain]);

  const handleLogin = useCallback(async () => {
    if (isLoggingIn) return;
    setIsLoggingIn(true);
    try {
      
      if (ual && ual.showModal) {
        await ual.showModal();
      } else {
        console.error('UAL instance or showModal method is undefined');
      }
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setIsLoggingIn(false);
    }
  }, [ual, currentChain, isLoggingIn]);

  const handleLogout = useCallback(async () => {
    try {
      if (ual && ual.logout) {
        await ual.logout();
      }
      logout();
      
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }, [ual, logout]);

  return (
    <div>
      {!isAuthenticated ? (
        <button
          onClick={handleLogin}
          disabled={isLoggingIn}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-500 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white disabled:opacity-50"
        >
          {isLoggingIn ? 'Logging in...' : 'Login'}
        </button>
      ) : (
        <button
          onClick={handleLogout}
          className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600 focus:ring-white"
        >
          Logout
        </button>
      )}
    </div>
  );
};

export default WalletConnector;
