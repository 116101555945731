import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import { CheckCircle, XCircle, Clock, Calendar, Layers } from 'lucide-react';
import ActionRenderer from './ActionRenderer';

const Transaction = () => {
  const { id } = useParams();
  const { currentChain } = useChainContext();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [blockNum, setBlockNum] = useState(null);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      setLoading(true);
      try {
        const blockchainService = new BlockchainService(currentChain);
        const response = await blockchainService.fetchFromChain(`/get_transaction/${id}`);
        
        setTransaction(response);

        // Extract timestamp and block_num from the first action
        if (response.actions && response.actions.length > 0) {
          setTimestamp(response.actions[0]['@timestamp']);
          setBlockNum(response.actions[0].block_num);
        }

        setError(null);
      } catch (err) {
        console.error('Error fetching transaction details:', err);
        setError('Failed to fetch transaction details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionDetails();
  }, [id, currentChain]);

  if (loading) return <div className="text-center py-8">Loading transaction details...</div>;
  if (error) return <div className="text-center py-8 text-red-500">Error: {error}</div>;
  if (!transaction) return <div className="text-center py-8">No transaction found</div>;

  const actions = transaction.actions || [];

  return (
    <div className="w-full max-w-3xl mx-auto px-2 md:px-4 py-8">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-center md:text-left">Transaction Details</h1>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Transaction ID: {transaction.trx_id}
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                {transaction.executed ? (
                  <CheckCircle className="mr-2 text-green-500" size={20} />
                ) : (
                  <XCircle className="mr-2 text-red-500" size={20} />
                )}
                Executed
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {transaction.executed ? 'Yes' : 'No'}
              </dd>
            </div>
            {timestamp && (
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                  <Calendar className="mr-2" size={20} />
                  Timestamp
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {timestamp}
                </dd>
              </div>
            )}
            {blockNum && (
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                  <Layers className="mr-2" size={20} />
                  Block Number
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {blockNum}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
      
      {/* Actions Table */}
      <div className="mt-8">
        <h2 className="text-xl md:text-2xl font-bold mb-4">Actions</h2>
        {actions.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contract</th>
                  <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Authorization</th>
                  <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {actions.map((action, index) => (
                  <tr key={index}>
                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{action.act.account}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{action.act.name}</td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      {action.act.authorization.map((auth, authIndex) => (
                        <div key={authIndex}>{auth.actor}@{auth.permission}</div>
                      ))}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-500">
                      <ActionRenderer action={action.act} accountName={null} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center text-sm text-gray-500">No actions found for this transaction.</p>
        )}
      </div>
    </div>
  );
};

export default Transaction;
