import React, { useState, useEffect } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import { Plus, Trash, AlertCircle } from 'lucide-react';
import ContractActionSearch from './ContractActionSearch';

const LinkManager = ({ accountName, permissions, onUpdateLinks }) => {
  const { user } = useChainContext();
  const [newLink, setNewLink] = useState({ code: '', type: '', requirement: '' });
  const [links, setLinks] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const allLinks = permissions.flatMap(permission => 
      permission.linked_actions.map(link => ({
        ...link,
        requirement: permission.perm_name
      }))
    );
    setLinks(allLinks);
  }, [permissions]);

  const handleContractActionSelect = (contract, action) => {
    setNewLink(prev => ({ ...prev, code: contract, type: action }));
  };

  const handleAddLink = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    if (!newLink.code || !newLink.type || !newLink.requirement) {
      setError('Please fill in all fields');
      setLoading(false);
      return;
    }
    try {
      const action = {
        account: 'eosio',
        name: 'linkauth',
        authorization: [{ actor: user.name, permission: user.session.requestPermission }],
        data: {
          account: accountName,
          code: newLink.code,
          type: newLink.type,
          requirement: newLink.requirement
        }
      };


      await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNewLink({ code: '', type: '', requirement: '' });
      onUpdateLinks();
    } catch (error) {
      console.error('Error adding link:', error);
      setError('Failed to add link: ' + error.message);
    }
    setLoading(false);
  };

  const handleRemoveLink = async (link) => {
    try {
      const action = {
        account: 'eosio',
        name: 'unlinkauth',
        authorization: [{ actor: user.name, permission: user.session.requestPermission }],
        data: {
          account: accountName,
          code: link.account,
          type: link.action
        }
      };



      await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      onUpdateLinks();
    } catch (error) {
      console.error('Error removing link:', error);
      setError('Failed to remove link: ' + error.message);
    }
  };

  return (
    <div className="space-y-6 p-6 bg-gray-100 rounded-lg shadow-md">
      <h3 className="text-xl font-bold text-gray-800 mb-4">Manage Links</h3>

      <div className="bg-white p-4 rounded-lg mb-4 shadow">
        <ContractActionSearch onSelect={handleContractActionSelect} />
      </div>

      {newLink.code && newLink.type && (
        <div className="bg-white p-4 rounded-lg mb-4 shadow">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <span className="font-semibold text-gray-800">Contract:</span>
              <span className="block mt-1 text-gray-700">{newLink.code}</span>
            </div>
            <div>
              <span className="font-semibold text-gray-800">Action:</span>
              <span className="block mt-1 text-gray-700">{newLink.type}</span>
            </div>
            <div>
              <span className="font-semibold text-gray-800">Permission:</span>
              <select
                value={newLink.requirement}
                onChange={(e) => setNewLink({ ...newLink, requirement: e.target.value })}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                required
              >
                <option value="">Select Permission</option>
                {permissions.map((perm) => (
                  <option key={perm.perm_name} value={perm.perm_name}>
                    {perm.perm_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {error && (
            <p className="text-sm text-red-600 flex items-center mt-2">
              <AlertCircle className="mr-1" size={16} />
              {error}
            </p>
          )}
          <div className="text-right mt-4">
            <button
              onClick={handleAddLink}
              disabled={loading}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150"
            >
              {loading ? 'Adding...' : 'Add Link'}
            </button>
          </div>
        </div>
      )}

      <table className="min-w-full bg-white rounded-lg overflow-hidden">
        <thead>
          <tr className="bg-indigo-600 text-white">
            <th className="px-4 py-3 text-left font-semibold">Contract</th>
            <th className="px-4 py-3 text-left font-semibold">Action</th>
            <th className="px-4 py-3 text-left font-semibold">Permission</th>
            <th className="px-4 py-3 text-left font-semibold">Actions</th>
          </tr>
        </thead>
        <tbody>
          {links.length === 0 ? (
            <tr>
              <td colSpan="4" className="text-center p-4 text-gray-500">No links found.</td>
            </tr>
          ) : (
            links.map((link, index) => (
              <tr key={index} className="hover:bg-gray-50 border-b">
                <td className="px-4 py-3 font-medium text-gray-800">{link.account}</td>
                <td className="px-4 py-3 font-medium text-gray-800">{link.action}</td>
                <td className="px-4 py-3 font-medium text-gray-800">{link.requirement}</td>
                <td className="px-4 py-3 text-right">
                  <button
                    onClick={() => handleRemoveLink(link)}
                    className="text-red-500 hover:text-red-700 transition duration-150"
                    aria-label="Remove link"
                    title="UNLINK"
                  >
                    <Trash size={16} />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default LinkManager;