import React, { useState, useEffect, useRef } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import { Search, AlertCircle } from 'lucide-react';
import { debounce } from 'lodash';

const ContractActionSearch = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { currentChain } = useChainContext();
  const suggestionsRef = useRef(null);
  const blockchainService = useRef(new BlockchainService(currentChain));

  useEffect(() => {
    blockchainService.current = new BlockchainService(currentChain);
  }, [currentChain]);

  const debouncedSearch = useRef(
    debounce(async (search) => {
      if (search.length >= 3) {
        setLoading(true);
        setError(null);
        try {
          const response = await blockchainService.current.searchAccounts(search);
          setSuggestions(response.rows.map(row => row.scope));
        } catch (err) {
          console.error('Error searching accounts:', err);
          setError('Error searching accounts');
          setSuggestions([]);
        } finally {
          setLoading(false);
        }
      } else {
        setSuggestions([]);
      }
    }, 300)
  ).current;

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm]);

  const fetchContractActions = async (contractName) => {
    setLoading(true);
    setError(null);
    try {
      const abiResponse = await blockchainService.current.fetchFromChain(`/get_abi?account_name=${contractName}`);

      if (abiResponse && abiResponse.abi) {
        setActions(abiResponse.abi.actions.map(action => action.name));
        setError(null);
      } else {
        setActions([]);
        setError('The provided account is not a smart contract.');
      }
    } catch (err) {
      console.error('Error fetching contract actions:', err);
      setError('Error fetching contract actions');
      setActions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setSearchTerm(suggestion);
    setSuggestions([]);
    await fetchContractActions(suggestion);
  };

  const handleActionSelect = (action) => {
    onSelect(searchTerm, action);
    setActions([]);
  };

  return (
    <div className="relative">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for a contract..."
          className="w-full px-4 py-2 text-gray-700 bg-white border rounded-full focus:border-purple-400 focus:outline-none focus:ring focus:ring-purple-300 focus:ring-opacity-40"
        />
        <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      </div>
      {loading && <p className="mt-2 text-sm text-gray-500">Searching...</p>}
      {error && (
        <p className="mt-2 text-sm text-red-500 flex items-center">
          <AlertCircle size={16} className="mr-1" /> {error}
        </p>
      )}
      {suggestions.length > 0 && (
        <ul ref={suggestionsRef} className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-gray-700"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      {actions.length > 0 && (
        <div className="mt-2">
          <p className="text-sm font-medium text-gray-700">Available actions:</p>
          <ul className="mt-1 max-h-60 overflow-auto border rounded-md divide-y">
            {actions.map((action, index) => (
              <li
                key={index}
                onClick={() => handleActionSelect(action)}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                {action}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ContractActionSearch;