import React, { useState, useEffect, useMemo } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import { Check, AlertCircle, RefreshCw } from 'lucide-react';

function Acciones({ contractName }) {
  const { currentChain, user } = useChainContext();
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState('');
  const [actionFields, setActionFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);
  const [isContract, setIsContract] = useState(true);

  const blockchainService = useMemo(() => new BlockchainService(currentChain), [currentChain]);

  useEffect(() => {
    const fetchActions = async () => {
      if (!contractName) {
        setError('Contract name is required.');
        return;
      }

      setLoading(true);
      setError(null);
      setActions([]);
      setNotification(null);
      setIsContract(true);

      try {
        const abiResponse = await blockchainService.fetchFromChain(`/get_abi?account_name=${contractName}`);

        if (abiResponse && abiResponse.abi) {
          const contractActions = abiResponse.abi.actions || [];
          const structs = abiResponse.abi.structs || [];

          if (contractActions.length === 0) {
            throw new Error('The contract has no actions defined in its ABI.');
          }

          setActions(contractActions.map(action => ({
            ...action,
            fields: structs.find(struct => struct.name === action.type)?.fields || []
          })));
          setNotification({ message: 'Actions successfully loaded.', type: 'success' });
        } else {
          setIsContract(false);
          throw new Error('The provided account is not a smart contract.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchActions();
  }, [blockchainService, contractName]);

  const handleActionSelect = (action) => {
    setSelectedAction(action.name);
    const fields = action.fields || [];
    setActionFields(fields);
    setFormData(fields.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {}));
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    // Validate form data
    for (const field of actionFields) {
      if (formData[field.name] === '') {
        setLoading(false);
        setError(`Field "${field.name}" is required.`);
        return;
      }
    }

    try {
      if (!user || !user.session) {
        throw new Error('User not authenticated');
      }

      const action = {
        account: contractName,
        name: selectedAction,
        authorization: [{ actor: user.name, permission: 'active' }],
        data: formData,
      };

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNotification({ message: 'Transaction pushed successfully.', type: 'success' });
    } catch (err) {
      setError(err.message || 'An error occurred during the transaction');
    } finally {
      setLoading(false);
    }
  };

  const renderActionButtons = () => {
    return (
      <div className="flex flex-wrap gap-2 mb-4">
        {actions.map(action => (
          <button
            key={action.name}
            onClick={() => handleActionSelect(action)}
            className={`px-4 py-2 rounded-md ${
              selectedAction === action.name
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            {action.name}
          </button>
        ))}
      </div>
    );
  };

  const renderActionForm = () => {
    if (!selectedAction || actionFields.length === 0) {
      return (
        <div className="bg-blue-100 text-blue-700 p-4 rounded-md">
          Select an action to proceed.
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {actionFields.map(field => (
          <div key={field.name}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {field.name} ({field.type})
            </label>
            <input
              type="text"
              value={formData[field.name]}
              onChange={(e) => handleInputChange(field.name, e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {notification && (
        <div
          className={`mb-4 p-4 rounded-md ${
            notification.type === 'success'
              ? 'bg-green-100 text-green-700'
              : 'bg-red-100 text-red-700'
          }`}
        >
          <div className="flex">
            <div className="flex-shrink-0">
              {notification.type === 'success' ? (
                <Check className="h-5 w-5 text-green-400" aria-hidden="true" />
              ) : (
                <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
              )}
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">{notification.message}</p>
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="mb-4 p-4 rounded-md bg-red-100 text-red-700">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">{error}</p>
            </div>
          </div>
        </div>
      )}

      <div className="mb-6">
        {renderActionButtons()}
      </div>

      {renderActionForm()}

      {selectedAction && (
        <div className="mb-6 flex justify-center space-x-4">
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="px-6 py-3 bg-indigo-600 text-white rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            {loading ? 'Processing...' : 'Push Transaction'}
          </button>
        </div>
      )}
    </div>
  );
}

export default Acciones;
