import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import AccountOverview from '../components/AcountOverview';
import { Loader2 } from 'lucide-react';

function AccountDetail() {
  const { accountName } = useParams();
  const { currentChain } = useChainContext();
  const [accountInfo, setAccountInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAccountInfo = async () => {
      setLoading(true);
      setError(null);
      try {
        const blockchainService = new BlockchainService(currentChain);
        const info = await blockchainService.fetchFromChain(`/get_account/${accountName}`);
        setAccountInfo(info);
      } catch (err) {
        setError(`Error fetching account info: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountInfo();
  }, [accountName, currentChain]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[200px] p-4">
        <div className="text-center">
          <Loader2 className="animate-spin h-8 w-8 mx-auto mb-4" />
          <p className="text-sm text-gray-600">Loading account details...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="mx-auto max-w-2xl p-4">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <p className="text-red-600">Error: {error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-6">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl bg-white rounded-lg shadow-sm">
        {accountInfo && (
          <div className="p-4 sm:p-6 lg:p-8">
            <AccountOverview accountInfo={accountInfo} />
          </div>
        )}
      </div>
    </div>
  );
}

export default AccountDetail;