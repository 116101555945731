import React, { useState } from 'react';
import { RefreshCw, X } from 'lucide-react';

const ActionFilter = ({ filters, onFilterChange, handleSubmit }) => {
  const categories = [
    'RECEIVE TOKEN', 'SEND TOKEN', 'ACCOUNT', 'RAM / CPU / NET', 'PRODUCER', 'VOTE'
  ];

  // Mantener un estado local para los campos de texto y fecha
  const [localFilters, setLocalFilters] = useState({
    contractName: filters.contractName,
    actionName: filters.actionName,
    after: filters.after,
    before: filters.before
  });

  // Manejar el cambio de categoría con toggle
  const handleCategoryChange = (category) => {
    const newCategory = filters.category === category ? '' : category;
    onFilterChange('category', newCategory);
    handleSubmit();
  };

  // Actualizar los valores de los filtros locales sin hacer submit
  const handleLocalFilterChange = (key, value) => {
    setLocalFilters(prev => ({ ...prev, [key]: value }));
  };

  // Aplicar los filtros locales al componente padre
  const applyLocalFilters = () => {
    Object.keys(localFilters).forEach((key) => {
      onFilterChange(key, localFilters[key]);
    });
    handleSubmit();
  };

  // Limpiar los filtros y aplicar los cambios
  const clearLocalFilters = () => {
    const clearedFilters = {
      contractName: '',
      actionName: '',
      after: '',
      before: ''
    };
    setLocalFilters(clearedFilters);
    onFilterChange('category', '');
    Object.keys(clearedFilters).forEach((key) => {
      onFilterChange(key, '');
    });
    handleSubmit();
  };

  return (
    <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4 rounded-lg shadow-lg text-white">
      <h3 className="text-lg font-bold mb-3">Action Filters</h3>
      
      {/* Category Buttons */}
      <div className="mb-4">
        <label className="block text-xs font-medium mb-1">
          Category:
        </label>
        <div className="flex flex-wrap gap-1">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryChange(category)}
              className={`px-2 py-1 rounded-full text-xs font-medium transition-all duration-200 ${
                filters.category === category
                  ? 'bg-white text-indigo-600 shadow-sm scale-105'
                  : 'bg-indigo-400 text-white hover:bg-indigo-300'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {/* Contract/Action and Date Filters */}
      <div className="bg-indigo-700 bg-opacity-50 p-3 rounded-lg mb-3">
        <div className="space-y-2">
          {/* Contract/Action Filter */}
          <div>
            <label className="block text-xs font-medium mb-1">
              Contract/Action:
            </label>
            <div className="flex space-x-1">
              <input
                type="text"
                value={localFilters.contractName}
                onChange={(e) => handleLocalFilterChange('contractName', e.target.value)}
                className="w-1/5 px-2 py-1 text-xs bg-indigo-100 text-indigo-900 rounded-md focus:outline-none focus:ring-1 focus:ring-white mx-1"
                placeholder="Contract"
              />
              <input
                type="text"
                value={localFilters.actionName}
                onChange={(e) => handleLocalFilterChange('actionName', e.target.value)}
                className="w-1/5 px-2 py-1 text-xs bg-indigo-100 text-indigo-900 rounded-md focus:outline-none focus:ring-1 focus:ring-white mx-1"
                placeholder="Action"
              />
            </div>
          </div>

          {/* Date Filter */}
          <div>
            <label className="block text-xs font-medium mb-1">
              Date Range:
            </label>
            <div className="flex space-x-1">
              <input
                type="datetime-local"
                value={localFilters.after}
                onChange={(e) => handleLocalFilterChange('after', e.target.value)}
                className="w-1/5 px-2 py-1 text-xs bg-indigo-100 text-indigo-900 rounded-md focus:outline-none focus:ring-1 focus:ring-white mx-1"
              />
              <input
                type="datetime-local"
                value={localFilters.before}
                onChange={(e) => handleLocalFilterChange('before', e.target.value)}
                className="w-1/5 px-2 py-1 text-xs bg-indigo-100 text-indigo-900 rounded-md focus:outline-none focus:ring-1 focus:ring-white mx-1"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-between">
        <button
          onClick={applyLocalFilters}
          className="px-3 py-1 bg-white text-indigo-600 rounded-md hover:bg-indigo-100 transition-colors duration-200 flex items-center font-medium text-xs"
        >
          <RefreshCw size={12} className="mr-1" />
          Apply Filters
        </button>
        <button
          onClick={clearLocalFilters}
          className="px-3 py-1 bg-indigo-400 text-white rounded-md hover:bg-indigo-300 transition-colors duration-200 flex items-center font-medium text-xs"
        >
          <X size={12} className="mr-1" />
          Clear Filters
        </button>
      </div>
    </div>
  );
};

//export default ActionFilter;
export default React.memo(ActionFilter);