import React, { useState, useEffect, useCallback } from 'react';
import { useChainContext } from '../../../contexts/ChainContext';
import { Cpu, HardDrive, User, DollarSign } from 'lucide-react';
import { getChainConfig } from '../../../config/chainConfig';


const AddCpuNet = ({ onTransactionSuccess }) => {


  const { currentChain, user } = useChainContext();
  const [balance, setBalance] = useState(0);
  const [receiver, setReceiver] = useState('');
  const [cpuQuantity, setCpuQuantity] = useState('');
  const [netQuantity, setNetQuantity] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [notification, setNotification] = useState(null);

  const chainConfig = getChainConfig(currentChain);
  const chainSymbol = chainConfig.symbol;

  const formatNumber = (number) => {
    return Number(number).toFixed(8);
  };

  const formatAsset = (amount, symbol) => {
    const [, precision] = symbol.split(',');
    return `${formatNumber(parseFloat(amount))} ${symbol.split(',')[0]}`;

  };

  const handleAddResource = async (e) => {

    
    e.preventDefault();
    setIsProcessing(true);
    setNotification(null);

    e.preventDefault();
    
    
    if (!cpuQuantity && !netQuantity) {
    
      setNotification({ message: 'Please enter a quantity for CPU or NET', type: 'error' });
      setIsProcessing(false);
      return;
    }


    try {
      if (!user || !user.session) {
        throw new Error('User not authenticated');
      }

      const cpuAmount = formatAsset(cpuQuantity, `${chainSymbol},4`);
      const netAmount = formatAsset(netQuantity, `${chainSymbol},4`);

    


      const action = {
        account: 'eosio',
        name: 'delegatebw',
        authorization: [{ actor: user.name, permission: 'active' }],
        data: {
          from: user.name,
          stake_net_quantity: netAmount,
          stake_cpu_quantity: cpuAmount,
          receiver: receiver,
          transfer: false
        }
      };

      

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      

      setNotification({ message: `Successfully added ${cpuAmount} to CPU and ${netAmount} to NET for ${receiver}.`, type: 'success' });
      setCpuQuantity('');
      setNetQuantity('');
      setReceiver('');

      
      onTransactionSuccess();
     
    } catch (error) {
      console.error('Error adding resources:', error);
      setNotification({ message: `Failed to add resources: ${error.message}`, type: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-8xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Add CPU/NET</h1>
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
          <div className="bg-white p-6 rounded-2xl">
            <form onSubmit={handleAddResource} className="space-y-6">
              <div>
                <label htmlFor="receiver" className="block text-sm font-medium text-gray-700 mb-1">Receiver</label>
                <div className="relative">
                  <input
                    id="receiver"
                    type="text"
                    value={receiver}
                    onChange={(e) => setReceiver(e.target.value)}
                    className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder="Enter account name"
                    required
                  />
                  <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div>
                  <label htmlFor="cpuQuantity" className="block text-sm font-medium text-gray-700 mb-1">CPU Quantity</label>
                  <div className="relative">
                    <input
                      id="cpuQuantity"
                      type="text"
                      value={cpuQuantity}
                      onChange={(e) => setCpuQuantity(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      placeholder={`0.0000 ${chainSymbol}`}
                      required
                    />
                    <Cpu className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  </div>
                </div>
                <div>
                  <label htmlFor="netQuantity" className="block text-sm font-medium text-gray-700 mb-1">NET Quantity</label>
                  <div className="relative">
                    <input
                      id="netQuantity"
                      type="text"
                      value={netQuantity}
                      onChange={(e) => setNetQuantity(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      placeholder={`0.0000 ${chainSymbol}`}
                      required
                    />
                    <HardDrive className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center disabled:opacity-50"
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Processing...' : 'Add CPU/NET'}
                </button>
              </div>
            </form>
          </div>
        </div>

        {notification && (
          <div className={`mt-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddCpuNet;