import React, { useState, useEffect, useMemo } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import { Check, AlertCircle, RefreshCw } from 'lucide-react';

function Tablas({ contractName }) {
  const { currentChain } = useChainContext();
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);
  const [isContract, setIsContract] = useState(true);
  
  const [scope, setScope] = useState('');
  const [lowerBound, setLowerBound] = useState('');
  const [upperBound, setUpperBound] = useState('');
  const [limit, setLimit] = useState(100);
  const [lastKey, setLastKey] = useState('');
  const [hasMore, setHasMore] = useState(false);

  const blockchainService = useMemo(() => new BlockchainService(currentChain), [currentChain]);

  useEffect(() => {
    const fetchTables = async () => {
      if (!contractName) {
        setError('Contract name is required.');
        return;
      }

      setLoading(true);
      setError(null);
      setTables([]);
      setTableData([]);
      setNotification(null);
      setIsContract(true);

      try {
        const abiResponse = await blockchainService.fetchFromChain(`/get_abi?account_name=${contractName}`);

        if (abiResponse && abiResponse.abi) {
          const contractTables = abiResponse.abi.tables || [];

          if (contractTables.length === 0) {
            throw new Error('The contract has no tables defined in its ABI.');
          }

          setTables(contractTables);
          setSelectedTable(contractTables[0].name);
          setScope(contractName);
          setNotification({ message: 'Tables successfully loaded.', type: 'success' });

          // Load data from the first table
          await fetchTableData(contractTables[0].name, contractName);
        } else {
          setIsContract(false);
          throw new Error('The provided account is not a smart contract.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTables();
  }, [blockchainService, contractName]);

  const fetchTableData = async (tableName, tableScope, loadMore = false) => {
    setLoading(true);
    setError(null);

    try {
      const response = await blockchainService.fetchFromChain('/get_table_rows', {
        method: 'POST',
        data: {
          code: contractName,
          scope: tableScope || scope,
          table: tableName || selectedTable,
          json: true,
          lower_bound: loadMore ? lastKey : lowerBound,
          upper_bound: upperBound,
          limit: limit,
        },
      });

      if (response.more) {
        setLastKey(response.next_key);
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      setTableData(prevData => loadMore ? [...prevData, ...response.rows] : response.rows);
      setNotification({ message: 'Table data loaded successfully.', type: 'success' });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTableSelect = (tableName) => {
    setSelectedTable(tableName);
    setTableData([]);
    setLastKey('');
    setHasMore(false);
    fetchTableData(tableName, scope);
  };

  const handleRefresh = () => {
    setTableData([]);
    setLastKey('');
    setHasMore(false);
    fetchTableData(selectedTable, scope);
  };

  const handleLoadMore = () => {
    fetchTableData(selectedTable, scope, true);
  };

  const renderTableButtons = () => {
    return (
      <div className="flex flex-wrap gap-2 mb-4">
        {tables.map(table => (
          <button
            key={table.name}
            onClick={() => handleTableSelect(table.name)}
            className={`px-4 py-2 rounded-md ${
              selectedTable === table.name
                ? 'bg-blue-600 text-white'
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            }`}
          >
            {table.name}
          </button>
        ))}
      </div>
    );
  };

  const renderTableData = () => {
    if (tableData.length === 0) {
      return (
        <div className="bg-blue-100 text-blue-700 p-4 rounded-md">
          No data in this table.
        </div>
      );
    }

    const headers = Object.keys(tableData[0]);

    return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                #
              </th>
              {headers.map((header) => (
                <th
                  key={header}
                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {index + 1}
                </td>
                {headers.map((header) => (
                  <td key={header} className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                    {typeof row[header] === 'object' && row[header] !== null
                      ? JSON.stringify(row[header])
                      : row[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (!isContract) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="mb-4 p-4 rounded-md bg-red-100 text-red-700">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">The provided account is not a smart contract.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {notification && (
        <div
          className={`mb-4 p-4 rounded-md ${
            notification.type === 'success'
              ? 'bg-green-100 text-green-700'
              : 'bg-red-100 text-red-700'
          }`}
        >
          <div className="flex">
            <div className="flex-shrink-0">
              {notification.type === 'success' ? (
                <Check className="h-5 w-5 text-green-400" aria-hidden="true" />
              ) : (
                <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
              )}
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">{notification.message}</p>
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="mb-4 p-4 rounded-md bg-red-100 text-red-700">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">{error}</p>
            </div>
          </div>
        </div>
      )}

      <div className="mb-6">
        {renderTableButtons()}
      </div>

      <div className="mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Scope</label>
          <input
            type="text"
            value={scope}
            onChange={(e) => setScope(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Lower Bound</label>
          <input
            type="text"
            value={lowerBound}
            onChange={(e) => setLowerBound(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Upper Bound</label>
          <input
            type="text"
            value={upperBound}
            onChange={(e) => setUpperBound(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Limit</label>
          <input
            type="number"
            value={limit}
            onChange={(e) => setLimit(Number(e.target.value))}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
      </div>

      <div className="mb-6 flex justify-center space-x-4">
        <button
          onClick={handleRefresh}
          disabled={loading}
          className="px-6 py-3 bg-indigo-600 text-white rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 flex items-center"
        >
          <RefreshCw className="mr-2 h-5 w-5" />
          {loading ? 'Loading...' : 'Refresh Table'}
        </button>
      </div>

      {renderTableData()}

      {hasMore && (
        <div className="mt-6 flex justify-center">
          <button
            onClick={handleLoadMore}
            disabled={loading}
            className="px-6 py-3 bg-green-600 text-white rounded-md shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default Tablas;