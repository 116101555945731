import React from 'react';
import { Wallet } from 'lucide-react';
import { useChainContext } from '../contexts/ChainContext';

const ResourcesSection = ({ accountInfo }) => {
  const { currentChain } = useChainContext();

  const chainConfig = {
    'wax-mainnet': { token: 'WAX' },
    'wax-testnet': { token: 'WAX' },
    'telos-mainnet': { token: 'TLOS' },
    'telos-testnet': { token: 'TLOS' },
  };

  const chainToken = chainConfig[currentChain]?.token || 'TOKEN';

  const formatNumber = (num) => {
    if (typeof num === 'string') {
      const [value, symbol] = num.split(' ');
      return `${parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 8 })} ${symbol || chainToken}`;
    }
    return parseFloat(num).toLocaleString(undefined, { maximumFractionDigits: 8 });
  };

  const calculatePercentage = (used, max) => {
    return max > 0 ? (used / max) * 100 : 0;
  };

  const ramPercentage = calculatePercentage(accountInfo.account.ram_usage, accountInfo.account.ram_quota);
  const cpuPercentage = calculatePercentage(accountInfo.account.cpu_limit?.used, accountInfo.account.cpu_limit?.max);
  const netPercentage = calculatePercentage(accountInfo.account.net_limit?.used, accountInfo.account.net_limit?.max);

  const refundCPU = parseFloat(accountInfo.account.refund_request?.cpu_amount.split(' ')[0]) || 0;
  const refundNET = parseFloat(accountInfo.account.refund_request?.net_amount.split(' ')[0]) || 0;
  const totalRefund = refundCPU + refundNET;

  const ResourceBar = ({ label, percentage, used, max, unit }) => (
    <div className="mb-4">
      <p className="text-sm text-gray-600 mb-1">{label}:</p>
      <div className="w-full bg-gray-200 rounded-full h-6 relative">
        <div 
          className={`h-6 rounded-full ${
            percentage > 90 ? 'bg-red-600' : percentage > 70 ? 'bg-yellow-600' : 'bg-green-600'
          }`} 
          style={{ width: `${percentage}%` }}
        >
        </div>
        <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold text-gray-800">
          {percentage.toFixed(2)}%
        </span>
      </div>
      <p className="text-xs text-gray-500 mt-1">
        {formatNumber(used)} / {formatNumber(max)} {unit}
      </p>
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <div className="flex items-center mb-4">
        <Wallet className="w-6 h-6 mr-2 text-green-500" />
        <h2 className="text-xl font-semibold">Resources</h2>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="mb-4">
            <p className="text-sm text-gray-600">Available:</p>
            <p className="font-semibold">{formatNumber(accountInfo.account.core_liquid_balance)}</p>
          </div>
          <div className="mb-4">
            <p className="text-sm text-gray-600">Refund:</p>
            <p className="font-semibold">{formatNumber(totalRefund || `0 ${chainToken}`)}</p>
          </div>
          <div className="mb-4">
            <p className="text-sm text-gray-600">CPU Staked:</p>
            <p className="font-semibold">{formatNumber(accountInfo.account.self_delegated_bandwidth?.cpu_weight || `0 ${chainToken}`)}</p>
          </div>
          <div className="mb-4">
            <p className="text-sm text-gray-600">NET Staked:</p>
            <p className="font-semibold">{formatNumber(accountInfo.account.self_delegated_bandwidth?.net_weight || `0 ${chainToken}`)}</p>
          </div>
        </div>
        <div>
          <ResourceBar 
            label="RAM" 
            percentage={ramPercentage}
            used={accountInfo.account.ram_usage}
            max={accountInfo.account.ram_quota}
            unit="bytes"
          />
          <ResourceBar 
            label="CPU" 
            percentage={cpuPercentage}
            used={accountInfo.account.cpu_limit?.used}
            max={accountInfo.account.cpu_limit?.max}
            unit="µs"
          />
          <ResourceBar 
            label="NET" 
            percentage={netPercentage}
            used={accountInfo.account.net_limit?.used}
            max={accountInfo.account.net_limit?.max}
            unit="bytes"
          />
        </div>
      </div>
    </div>
  );
};

export default ResourcesSection;