import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import { 
  ArrowUpRight, 
  ArrowDownRight, 
  Lock, 
  Database, 
  Cpu, 
  Globe, 
  Twitter, 
  Send, 
  Loader2 
} from 'lucide-react';
import TokenFetcher from './TokenFetcher';
import { Link } from 'react-router-dom';
import { getChainConfig } from '../config/chainConfig';
import ReactCountryFlag from "react-country-flag";

// Memoized card component for blockchain information
const BlockchainInfoCard = React.memo(({ title, value, icon: Icon, change }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4 flex items-center justify-between">
      <div>
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <p className="mt-1 text-xl font-semibold text-gray-900">{value}</p>
        {change !== undefined && (
          <p className={`mt-1 text-sm ${change >= 0 ? 'text-green-600' : 'text-red-600'} flex items-center`}>
            {change >= 0 ? <ArrowUpRight size={16} /> : <ArrowDownRight size={16} />}
            {Math.abs(change).toLocaleString()}
          </p>
        )}
      </div>
      <div className="bg-blue-100 rounded-full p-3">
        <Icon size={24} className="text-blue-600" />
      </div>
    </div>
  );
});

// Memoized component for producer social links
const ProducerLinks = React.memo(({ website, twitter, telegram }) => {
  if (!website && !twitter && !telegram) return null;

  return (
    <div className="flex space-x-3">
      {website && (
        <a
          href={website}
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-600 hover:text-green-700 transition-colors duration-200"
          title="Website"
        >
          <Globe size={16} />
        </a>
      )}
      {twitter && (
        <a
          href={`https://twitter.com/${twitter}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400 hover:text-blue-500 transition-colors duration-200"
          title="Twitter"
        >
          <Twitter size={16} />
        </a>
      )}
      {telegram && (
        <a
          href={`https://t.me/${telegram}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:text-blue-600 transition-colors duration-200"
          title="Telegram"
        >
          <Send size={16} />
        </a>
      )}
    </div>
  );
});

// Memoized producer row component
const ProducerRow = React.memo(({ row, currentProducer, prepareRow }) => {
  prepareRow(row);
  return (
    <tr
      {...row.getRowProps()}
      className={`hover:bg-gray-50 ${row.original.owner === currentProducer ? 'bg-green-100' : ''}`}
    >
      {row.cells.map(cell => (
        <td
          key={cell.getCellProps().key}
          {...cell.getCellProps()}
          className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500"
        >
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
});

// Memoized producers table component
const ProducersTable = React.memo(({ tableProps, headerGroups, rows, prepareRow, currentProducer }) => {
  return (
    <div className="overflow-x-auto bg-white shadow-md rounded">
      <table {...tableProps} className="min-w-full">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map(row => (
            <ProducerRow
              key={row.original.owner}
              row={row}
              currentProducer={currentProducer}
              prepareRow={prepareRow}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
});

// Main Monitor component
function Monitor() {
  const { currentChain } = useChainContext();
  const [producers, setProducers] = useState([]);
  const [currentProducer, setCurrentProducer] = useState('');
  const [blockchainInfo, setBlockchainInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPrice, setCurrentPrice] = useState({ price: 0, marketcap: 0, volume: 0 });
  
  // Memoize blockchain service instance
  const blockchainService = useMemo(
    () => new BlockchainService(currentChain),
    [currentChain]
  );

  // Memoize chain configuration
  const chainConfig = useMemo(
    () => getChainConfig(currentChain),
    [currentChain]
  );

  // Effect for fetching current price
  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await blockchainService.fetchFromChain('/current_price');
        setCurrentPrice(response);
      } catch (err) {
        console.error('Error fetching current price:', err);
        // Don't set error state as this is not critical
      }
    };

    fetchPrice();
    const priceInterval = setInterval(fetchPrice, 60000); // Update price every minute

    return () => clearInterval(priceInterval);
  }, [blockchainService]);

  // Effect for fetching producers and blockchain info
  useEffect(() => {
    if (!blockchainService) return;

    const fetchProducers = async () => {
      try {
        const response = await blockchainService.fetchFromChain('/get_table_rows', {
          scope: 'eosio',
          code: 'eosio',
          table: 'producers',
          json: true,
          limit: 1000
        });

        const activeProducers = response.rows.filter(producer => producer.is_active);

        // Enrich producers with additional information
        const enrichedProducers = await Promise.all(
          activeProducers.map(async producer => {
            try {
              const producerInfo = await blockchainService.fetchFromChain(
                `/bp_data?producerName=${encodeURIComponent(producer.owner)}`
              );
              return { ...producer, producerInfo };
            } catch (error) {
              console.error(`Error fetching info for producer ${producer.owner}:`, error);
              return { ...producer, producerInfo: null };
            }
          })
        );

        setProducers(
          enrichedProducers.sort((a, b) => parseFloat(b.total_votes) - parseFloat(a.total_votes))
        );
        setError(null);
      } catch (err) {
        setError(`Failed to fetch producers data: ${err.message}`);
        setProducers([]);
      }
    };

    const fetchBlockchainInfo = async () => {
      try {
        const info = await blockchainService.getInfo();
        setCurrentProducer(info.head_block_producer);
        setBlockchainInfo(prevInfo => ({
          ...info,
          libChange: prevInfo ? info.last_irreversible_block_num - prevInfo.last_irreversible_block_num : 0
        }));
      } catch (err) {
        console.error('Failed to fetch blockchain info:', err);
      }
    };

    // Initial fetches
    Promise.all([fetchProducers(), fetchBlockchainInfo()])
      .finally(() => setLoading(false));

    // Set up intervals
    const producersInterval = setInterval(fetchProducers, 60000); // Every minute
    const blockchainInfoInterval = setInterval(fetchBlockchainInfo, 4000); // Every 4 seconds

    return () => {
      clearInterval(producersInterval);
      clearInterval(blockchainInfoInterval);
    };
  }, [blockchainService]);

  // Memoize vote weight calculation function
  const calculateVoteWeight = useMemo(() => {
    return (votes, chainFamily) => {
      if (chainFamily === 'WAX') {
        const epoch = 946684800000.0;
        const now = Date.now();
        const firstcalc = (now / 1000) - (epoch / 1000);
        const floor = Math.floor(firstcalc / (86400 * 7)) / 13;
        const weight = Math.pow(2, floor);
        return votes / weight / 100000000;
      } else if (chainFamily === 'TELOS') {
        return votes / 10000;
      }
      return votes;
    };
  }, []);

  // Memoize table columns
  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: '#',
        id: 'row',
        Cell: ({ row }) => row.index + 1,
        disableSortBy: true,
      },
      {
        Header: 'Producer',
        accessor: 'owner',
        Cell: ({ row }) => (
          <div className="flex items-center">
            {row.original.producerInfo?.logo && (
              <img
                src={row.original.producerInfo.logo}
                alt="Producer Logo"
                className="w-8 h-8 mr-2 object-contain"
                onError={(e) => { e.target.style.display = 'none' }}
              />
            )}
            <Link
              to={`/account-detail/${row.original.owner}`}
              className="text-blue-600 hover:text-blue-800 group"
            >
              <span>{row.original.owner}</span>
              <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-blue-600" />
            </Link>
          </div>
        ),
      },
      {
        Header: 'Country',
        id: 'country',
        Cell: ({ row }) => {
          const country = row.original.producerInfo?.country;
          if (!country) return <span>N/A</span>;
          
          return (
            <div className="flex items-center">
              <ReactCountryFlag 
                countryCode={country}
                svg
                style={{ width: '1em', height: '1em' }}
              />
              <span className="ml-2">{country}</span>
            </div>
          );
        },
      },
      {
        Header: 'Social',
        id: 'social',
        Cell: ({ row }) => {
          const info = row.original.producerInfo;
          return (
            <ProducerLinks
              website={info?.website}
              twitter={info?.twitter}
              telegram={info?.telegram}
            />
          );
        },
      },
      {
        Header: 'Total Votes',
        accessor: 'total_votes',
        Cell: ({ value }) => {
          const calculatedVotes = calculateVoteWeight(
            parseFloat(value),
            blockchainService.getChainFamily()
          );
          return calculatedVotes.toLocaleString(undefined, {
            maximumFractionDigits: 0
          });
        },
      }
    ];

    // Add Telos-specific rewards column
    if (blockchainService.getChainFamily() === 'TELOS') {
      baseColumns.push({
        Header: 'Rewards 24H',
        id: 'rewards',
        Cell: ({ row }) => {
          const MAX_REWARDS = 328.767123288;
          const tokenPrice = currentPrice?.price || 0;
          const position = row.index + 1;

          const calculateRewards = () => {
            if (tokenPrice === 0) return 0;
            
            let rewards = 4.17 * Math.pow(tokenPrice, -0.516) * 48;
            rewards = Math.min(rewards, MAX_REWARDS);

            if (position <= 21) return rewards;
            if (position <= 42) return rewards / 2;
            return 0;
          };

          const rewards = calculateRewards();

          return (
            <div className="flex items-center whitespace-nowrap">
              <span>{rewards.toFixed(2)}</span>
              <span className="ml-1 text-gray-500">TLOS</span>
            </div>
          );
        }
      });
    }

    return baseColumns;
  }, [blockchainService, currentPrice?.price, calculateVoteWeight]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: producers });

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <Loader2 className="animate-spin h-8 w-8 mb-4 mx-auto text-blue-600" />
          <p className="text-gray-600">Loading blockchain data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-8">
        <div className="bg-red-50 text-red-700 p-4 rounded-lg inline-block">
          <h3 className="font-bold mb-2">Error Loading Data</h3>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4">
      {blockchainInfo && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <BlockchainInfoCard
            title="Head Block"
            value={blockchainInfo.head_block_num.toLocaleString()}
            icon={Database}
          />
          <BlockchainInfoCard
            title="Last Irreversible Block"
            value={blockchainInfo.last_irreversible_block_num.toLocaleString()}
            icon={Lock}
          />
          <BlockchainInfoCard
            title="Current Producer"
            value={currentProducer}
            icon={Cpu}
          />
          <BlockchainInfoCard
            title="Price / Mcap / Vol"
            value={`$${parseFloat(currentPrice.price).toFixed(3)} / $${Math.floor(currentPrice.marketcap/1000000)}M / $${Math.floor(currentPrice.volume/1000000)}M`}
            icon={ArrowUpRight}
          />
        </div>
      )}

      <div className="bg-white shadow-md rounded p-4 mb-6 transition-all duration-300 hover:shadow-lg">
        <h2 className="text-xl font-semibold mb-4">
          Token Price ({blockchainService?.getChainSymbol()})
        </h2>
        <div className="h-[200px]">
          <TokenFetcher />
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-4 bg-gradient-to-r from-blue-50 to-indigo-50 border-b">
          <h2 className="text-lg font-semibold text-gray-800">
            Block Producers
          </h2>
          <p className="text-sm text-gray-600">
            Updated every minute • {producers.length} active producers
          </p>
        </div>
        
        <ProducersTable
          tableProps={getTableProps()}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          currentProducer={currentProducer}
        />
      </div>
    </div>
  );
}

// Optimize overall component re-renders
export default React.memo(Monitor);