import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ChainProvider } from './contexts/ChainContext';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './pages/Home';
import BlockExplorer from './pages/BlockExplorer';
import Monitor from './pages/Monitor';
import TokenFetcher from './pages/TokenFetcher';
import AccountDetail from './pages/AccountDetail';
import Transaction from './components/Transaction';
import TransferTokens from './pages/wallet/TransferTokens';
import Voters from './pages/wallet/Voters';
import Resources from './pages/wallet/Resources';
import favicon from './assets/favicon.ico';
import Keys from './pages/wallet/Keys';
import MSIG from './pages/MSIG';
import ProposalDetails from './pages/ProposalDetails';
import TotalAmount from './pages/TopHolders/TotalAmount';
import RAM from './pages/TopHolders/RAM';
import REX from './pages/TopHolders/REX';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    // Agregar el favicon
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = favicon;
    document.head.appendChild(link);

    // Limpieza al desmontar el componente
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <ChainProvider>
      <Router>
        <AppContent sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      </Router>
    </ChainProvider>
  );
}

function AppContent({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = getPageTitle(location.pathname);
    document.title = `${pageTitle} | ChainScout`;
  }, [location]);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar isOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header setSidebarOpen={setSidebarOpen} className="mb-4" />
        
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Monitor />} />
            <Route path="/explorer" element={<BlockExplorer />} />
            <Route path="/wallet/transfer" element={<TransferTokens />} />
            <Route path="/token-fetcher" element={<TokenFetcher />} />
            <Route path="/account-detail/:accountName" element={<AccountDetail />} />
            <Route path="/wallet/vote" element={<Voters />} />
            <Route path="/wallet/cpu-net-ram" element={<Resources />} />
            <Route path="/transaction/:id" element={<Transaction />} />
            <Route path="/wallet/keys" element={<Keys />} />
            <Route path="/multisig" element={<MSIG />} />
            <Route path="/proposal/:proposer/:proposalName" element={<ProposalDetails />} />
            {/* Añadir las nuevas rutas para Top Holders */}
            <Route path="/top-holders/total" element={<TotalAmount />} />
            <Route path="/top-holders/ram" element={<RAM />} />
            <Route path="/top-holders/rex" element={<REX />} />
          </Routes>
        </main>
        
        <Footer />
      </div>
    </div>
  );
}

function getPageTitle(pathname) {
  switch (pathname) {
    case '/home':
      return 'Home';
    case '/':
      return 'Monitor';
    case '/explorer':
      return 'Block Explorer';
    case '/wallet/transfer':
      return 'Transfer Tokens';
    case '/token-fetcher':
      return 'Token Fetcher';
    case '/wallet/vote':
      return 'Voters';
    case '/wallet/cpu-net-ram':
      return 'Resources';
    // Añadir los nuevos títulos para Top Holders
    case '/top-holders/total':
      return 'Top Token Holders';
    case '/top-holders/ram':
      return 'Top RAM Holders';
    case '/top-holders/rex':
      return 'Top REX Holders';
    default:
      return 'Blockchain Explorer and Wallet';
  }
}

export default App;
