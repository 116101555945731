import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useChainContext } from '../../../contexts/ChainContext';
import { Cpu, HardDrive, User, DollarSign } from 'lucide-react';
import { getChainConfig } from '../../../config/chainConfig';

const RemoveCpuNet = ({ onTransactionSuccess }) => {
  const { currentChain, user } = useChainContext();
  const [resources, setResources] = useState({ cpu: 0, net: 0 });
  const [cpuQuantity, setCpuQuantity] = useState('');
  const [netQuantity, setNetQuantity] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [notification, setNotification] = useState(null);

  const chainConfig = getChainConfig(currentChain);
  const chainSymbol = chainConfig.symbol;

  const formatNumber = (number) => {
    return Number(number).toFixed(8);
  };

  const formatAsset = (amount, symbol) => {
    const [, precision] = symbol.split(',');
    return `${formatNumber(parseFloat(amount))} ${symbol.split(',')[0]}`;
  };

  const formattedCpuQuantity = useMemo(() => {
    if (!cpuQuantity) return '0.00000000';
    return formatNumber(parseFloat(cpuQuantity));
  }, [cpuQuantity]);

  const formattedNetQuantity = useMemo(() => {
    if (!netQuantity) return '0.00000000';
    return formatNumber(parseFloat(netQuantity));
  }, [netQuantity]);

  const handleRemoveResource = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setNotification(null);

    try {
      if (!user || !user.session) {
        throw new Error('User not authenticated');
      }

      if (!cpuQuantity && !netQuantity) {
        throw new Error('Please enter a quantity for CPU or NET');
      }

      const cpuAmount = formatAsset(formattedCpuQuantity, `${chainSymbol},4`);
      const netAmount = formatAsset(formattedNetQuantity, `${chainSymbol},4`);

      const action = {
        account: 'eosio',
        name: 'undelegatebw',
        authorization: [{ actor: user.name, permission: 'active' }],
        data: {
          from: user.name,
          receiver: user.name,
          unstake_net_quantity: netAmount,
          unstake_cpu_quantity: cpuAmount
        }
      };

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNotification({ message: `Successfully removed ${cpuAmount} from CPU and ${netAmount} from NET.`, type: 'success' });
      setCpuQuantity('');
      setNetQuantity('');
      onTransactionSuccess();
    } catch (error) {
      console.error('Error removing resources:', error);
      setNotification({ message: `Failed to remove resources: ${error.message}`, type: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-8xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Remove CPU/NET</h1>
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
          <div className="bg-white p-6 rounded-2xl">
            <form onSubmit={handleRemoveResource} className="space-y-6">
              
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div>
                  <label htmlFor="cpuQuantity" className="block text-sm font-medium text-gray-700 mb-1">CPU Quantity to Remove</label>
                  <div className="relative">
                    <input
                      id="cpuQuantity"
                      type="text"
                      value={cpuQuantity}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value) || value === '') {
                          setCpuQuantity(value);
                        }
                      }}
                      className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      placeholder={`0.00000000 ${chainSymbol}`}
                    />
                    <Cpu className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    Formatted: {formattedCpuQuantity} {chainSymbol}
                  </p>
                </div>
                <div>
                  <label htmlFor="netQuantity" className="block text-sm font-medium text-gray-700 mb-1">NET Quantity to Remove</label>
                  <div className="relative">
                    <input
                      id="netQuantity"
                      type="text"
                      value={netQuantity}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value) || value === '') {
                          setNetQuantity(value);
                        }
                      }}
                      className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      placeholder={`0.00000000 ${chainSymbol}`}
                    />
                    <HardDrive className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    Formatted: {formattedNetQuantity} {chainSymbol}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center disabled:opacity-50"
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Processing...' : 'Remove CPU/NET'}
                </button>
              </div>
            </form>
          </div>
        </div>

        {notification && (
          <div className={`mt-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default RemoveCpuNet;