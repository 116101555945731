import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

const ChainContext = createContext();

export const useChainContext = () => useContext(ChainContext);

export const ChainProvider = ({ children }) => {
  
  const getInitialChain = () => {
    const subdomain = window.location.hostname.split('.')[0];
    switch (subdomain) {
      case 'wax':
        return 'wax-mainnet';
      case 'telos':
        return 'telos-mainnet';
      case 'wax-testnet':
        return 'wax-testnet';
      case 'telos-testnet':
        return 'telos-testnet';
      default:
        console.warn(`Subdominio desconocido: ${subdomain}. Usando 'telos-mainnet' por defecto.`);
        return 'telos-mainnet';
    }
  };
  
  const [currentChain, setCurrentChain] = useState(getInitialChain());
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = useCallback((userData) => {
    setUser(userData);
    setIsAuthenticated(true);
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setIsAuthenticated(false);
  }, []);

  useEffect(() => {
    const subdomain = window.location.hostname.split('.')[0];
    switch (subdomain) {
      case 'wax':
        setCurrentChain('wax-mainnet');
        break;
      case 'telos':
        setCurrentChain('telos-mainnet');
        break;
      case 'wax-testnet':
        setCurrentChain('wax-testnet');
        break;
      case 'telos-testnet':
        setCurrentChain('telos-testnet');
        break;
      default:
        console.warn(`Subdominio desconocido: ${subdomain}. Usando 'telos-mainnet' por defecto.`);
        setCurrentChain('telos-mainnet');
    }
  }, [window.location.hostname]); 


  const changeChain = useCallback((newChain) => {
    if (isAuthenticated) {
      // Logout user when changing chains
      logout();
    }
    setCurrentChain(newChain);
  }, [isAuthenticated, logout]);

  return (
    <ChainContext.Provider value={{ 
      currentChain, 
      setCurrentChain: changeChain, 
      user, 
      isAuthenticated, 
      login, 
      logout 
    }}>
      {children}
    </ChainContext.Provider>
  );
};