import React, { useState, useEffect, useMemo } from 'react';
import { useChainContext } from '../../contexts/ChainContext';
import BlockchainService from '../../services/blockchainService';
import { Check, AlertCircle, ChevronUp, ChevronDown } from 'lucide-react';
import { getChainConfig } from '../../config/chainConfig';

function Voters() {
  const { currentChain, user, isAuthenticated } = useChainContext();
  const [producers, setProducers] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);
  const [proxies, setProxies] = useState([]);
  const [selectedProxy, setSelectedProxy] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVoting, setIsVoting] = useState(false);
  const [notification, setNotification] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'owner', direction: 'asc' });
  const [activeTab, setActiveTab] = useState('producers');
  const blockchainService = useMemo(() => new BlockchainService(currentChain), [currentChain]);
  //const chainConfig = useMemo(() => getChainConfig(currentChain), [currentChain]);

  
  

  //get chainConfig.family from chainConfig with currentChain
  const chainConfig = getChainConfig(currentChain);
  

  const isTelos = useMemo(() => chainConfig.chainFamily === 'TELOS', [chainConfig]);

  

  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [producersResponse, proxiesResponse] = await Promise.all([
          blockchainService.fetchFromChain('/get_table_rows', {
            scope: 'eosio',
            code: 'eosio',
            table: 'producers',
            json: true,
            limit: 1000
          }),
          blockchainService.fetchFromChain('/proxies')
        ]);

        const activeProducers = producersResponse.rows
          .filter(producer => producer.is_active)
          .map(producer => ({
            ...producer,
            total_votes: parseFloat(producer.total_votes)
          }));
        setProducers(activeProducers);

        const uniqueProxies = proxiesResponse.map((proxy, index) => ({
          ...proxy,
          key: `proxy-${proxy.owner}-${index}` // Clave única para cada proxy
        }));


        setProxies(uniqueProxies);
        setError(null);
      } catch (err) {
        setError('Failed to fetch data: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [blockchainService]);

  const sortedProducers = useMemo(() => {
    let sortableProducers = [...producers];
    if (sortConfig.key) {
      sortableProducers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProducers;
  }, [producers, sortConfig]);

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleProducerSelection = (producer) => {
    setSelectedProducers(prevSelected => {
      if (prevSelected.includes(producer)) {
        return prevSelected.filter(p => p !== producer);
      } else if (prevSelected.length < 30) {
        return [...prevSelected, producer];
      }
      return prevSelected;
    });
    setSelectedProxy(''); // Clear proxy selection when selecting producers
  };

  const handleProxySelection = (proxy) => {
    setSelectedProxy(selectedProxy === proxy ? '' : proxy);
    setSelectedProducers([]); // Clear producer selections when selecting a proxy
  };

  const handleVote = async () => {
    if (!isAuthenticated) {
      setNotification({ message: 'Please login to vote', type: 'error' });
      return;
    }

    setIsVoting(true);
    try {
      const action = {
        account: 'eosio',
        name: 'voteproducer',
        authorization: [{ actor: user.name, permission: 'active' }],
        data: {
          voter: user.name,
          proxy: selectedProxy,
          producers: selectedProxy ? [] : selectedProducers.sort()
        }
      };

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNotification({ message: 'Vote submitted successfully!', type: 'success' });
    
    } catch (err) {
      setNotification({ message: 'Voting failed: ' + err.message, type: 'error' });
    } finally {
      setIsVoting(false);
    }
  };

  const renderTabs = () => {

    

    if (isTelos) return (
      <div className="mb-4">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            <button
              className={`${
                activeTab === 'producers'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
              onClick={() => setActiveTab('producers')}
            >
              Producers
            </button>
          </nav>
        </div>
      </div>
    );
  
    return (
      <div className="mb-4">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            <button
              className={`${
                activeTab === 'producers'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
              onClick={() => setActiveTab('producers')}
            >
              Producers
            </button>
            <button
              className={`${
                activeTab === 'proxies'
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8`}
              onClick={() => setActiveTab('proxies')}
            >
              Proxies
            </button>
          </nav>
        </div>
      </div>
    );
  };
  

  const renderContent = () => {
    if (loading) return <div className="text-center p-4">Loading...</div>;
    if (error) return <div className="text-center p-4 text-red-500">Error: {error}</div>;

    if (isTelos || activeTab === 'producers') {
      return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {sortedProducers.map((producer) => (
            <div
              key={producer.owner}
              className={`p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200 ${
                selectedProducers.includes(producer.owner) ? 'bg-indigo-50 border-indigo-300' : 'border-gray-200'
              }`}
              onClick={() => handleProducerSelection(producer.owner)}
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedProducers.includes(producer.owner)}
                  onChange={() => {}}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded mr-2"
                />
                <span className="text-sm font-medium text-gray-900">{producer.owner}</span>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (activeTab === 'proxies') {
      return (
        <div className="space-y-2">
          {proxies.map((proxy) => (
            <div
              key={proxy.key}
              className={`p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200 ${
                selectedProxy === proxy.owner ? 'bg-indigo-50 border-indigo-300' : 'border-gray-200'
              }`}
              onClick={() => handleProxySelection(proxy.owner)}
            >
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium text-gray-900">{proxy.owner}</span>
                
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Vote </h1>
        
        {notification && (
          <div className={`mb-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            <div className="flex">
              <div className="flex-shrink-0">
                {notification.type === 'success' ? (
                  <Check className="h-5 w-5 text-green-400" aria-hidden="true" />
                ) : (
                  <AlertCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
                )}
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium">{notification.message}</p>
              </div>
            </div>
          </div>
        )}
        
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
          <div className="bg-white p-6 rounded-2xl">
            <div className="mb-4 flex justify-between items-center">
              <p className="text-lg">
                {selectedProxy ? (
                  <span>Selected Proxy: <span className="font-bold text-indigo-600">{selectedProxy}</span></span>
                ) : (
                  <span>Selected Producers: <span className="font-bold text-indigo-600">{selectedProducers.length}/30</span></span>
                )}
              </p>
              <button
                onClick={handleVote}
                disabled={(selectedProducers.length === 0 && !selectedProxy) || isVoting || !isAuthenticated}
                className="px-4 py-2 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 transition-colors duration-200"
              >
                {isVoting ? 'Submitting Vote...' : 'Submit Vote'}
              </button>
            </div>

            {!isTelos && renderTabs()}
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Voters;