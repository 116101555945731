import React, { useState, useEffect } from 'react';
import { User } from 'lucide-react';
import { useChainContext } from '../contexts/ChainContext';
import { getChainConfig } from '../config/chainConfig';
import BlockchainService from '../services/blockchainService';

const AccountSummary = ({ accountInfo }) => {
  const { currentChain } = useChainContext();
  const [priceInfo, setPriceInfo] = useState(null);
  const [rexInfo, setRexInfo] = useState(null);
  const [isPriceLoading, setIsPriceLoading] = useState(true);
  const [isRexLoading, setIsRexLoading] = useState(true);
  
  const chainConfig = getChainConfig(currentChain);
  const blockchainService = new BlockchainService(currentChain);

  // Fetch Price info
  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const priceData = await blockchainService.fetchFromChain('/current_price');
        setPriceInfo(priceData);
      } catch (err) {
        console.error('Error fetching price:', err);
      } finally {
        setIsPriceLoading(false);
      }
    };

    fetchPrice();
  }, [blockchainService]);

  // Fetch REX info for TELOS chains
  useEffect(() => {
    const fetchRexInfo = async () => {
      if (chainConfig.chainFamily === 'TELOS' && accountInfo.account_name) {
        try {
          const rexData = await blockchainService.fetchFromChain(`/get_rex_info/${accountInfo.account_name}`);
          setRexInfo(rexData);
        } catch (error) {
          console.error('Error fetching REX info:', error);
        } finally {
          setIsRexLoading(false);
        }
      } else {
        setIsRexLoading(false);
      }
    };

    fetchRexInfo();
  }, [currentChain, accountInfo.account_name, chainConfig.chainFamily, blockchainService]);

  // Check if all loading is done
  const isLoading = isPriceLoading || isRexLoading;

  // Loading messages
  const loadingMessages = [];
  if (isPriceLoading) {
    loadingMessages.push('Loading price information...');
  }
  if (isRexLoading) {
    loadingMessages.push('Loading REX information...');
  }

  const formatNumber = (num, token = chainConfig.token) => {
    if (typeof num === 'string') {
      const [value, symbol] = num.split(' ');
      return `${parseFloat(value).toLocaleString(undefined, { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      })} ${symbol || token}`;
    }
    return `${parseFloat(num).toLocaleString(undefined, { 
      minimumFractionDigits: 2,
      maximumFractionDigits: 2 
    })} ${token}`;
  };

  // Calculate all balances
  const availableBalance = parseFloat(accountInfo.core_liquid_balance?.split(' ')[0]) || 0;
  
  const refundCpu = parseFloat(accountInfo.refund_request?.cpu_amount?.split(' ')[0]) || 0;
  const refundNet = parseFloat(accountInfo.refund_request?.net_amount?.split(' ')[0]) || 0;
  const refundAmount = refundCpu + refundNet;
  
  const cpuStaked = parseFloat(accountInfo.self_delegated_bandwidth?.cpu_weight?.split(' ')[0]) || 0;
  const netStaked = parseFloat(accountInfo.self_delegated_bandwidth?.net_weight?.split(' ')[0]) || 0;
  const totalStaked = cpuStaked + netStaked;

  const rexValue = chainConfig.chainFamily === 'TELOS' && rexInfo ? rexInfo.tlos_value : 0;
  const totalBalance = availableBalance + refundAmount + totalStaked + rexValue;
  const totalBalanceUsd = priceInfo?.price ? totalBalance * priceInfo.price : null;

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <div className="flex items-center mb-4">
        <User className="w-8 h-8 mr-3 text-blue-500" />
        <h1 className="text-3xl font-bold">{accountInfo.account_name}</h1>
      </div>
      
      <div className="mt-4">
        <p className="text-xl font-semibold">Total Balance</p>
        <p className="text-4xl font-bold text-blue-600">
          {formatNumber(totalBalance)}
        </p>

        {isLoading ? (
          <div className="text-sm text-gray-600 mt-1">
            {loadingMessages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </div>
        ) : totalBalanceUsd ? (
          <p className="text-sm text-gray-600 mt-1">
            ≈ ${totalBalanceUsd.toLocaleString(undefined, { 
              minimumFractionDigits: 2,
              maximumFractionDigits: 2 
            })} USD
          </p>
        ) : null}
      </div>

      <div className="mt-6 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-sm text-gray-600">Available Balance</p>
            <p className="text-lg font-semibold">{formatNumber(availableBalance)}</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-sm text-gray-600">Total Staked</p>
            <p className="text-lg font-semibold">{formatNumber(totalStaked)}</p>
            <div className="mt-1 text-sm">
              <p className="text-gray-500">CPU: {formatNumber(cpuStaked)}</p>
              <p className="text-gray-500">NET: {formatNumber(netStaked)}</p>
            </div>
          </div>
        </div>

        {refundAmount > 0 && (
          <div className="bg-yellow-50 p-4 rounded-lg">
            <p className="text-sm text-yellow-800">Pending Refund</p>
            <p className="text-lg font-semibold text-yellow-900">{formatNumber(refundAmount)}</p>
            <div className="mt-1 text-sm">
              <p className="text-yellow-700">CPU: {formatNumber(refundCpu)}</p>
              <p className="text-yellow-700">NET: {formatNumber(refundNet)}</p>
            </div>
          </div>
        )}

        {chainConfig.chainFamily === 'TELOS' && rexInfo && rexInfo.tlos_value > 0 && (
          <div className="bg-blue-50 p-4 rounded-lg">
            <p className="text-sm text-blue-800">REX Balance</p>
            <p className="text-lg font-semibold text-blue-900">
              {formatNumber(rexInfo.tlos_value)}
            </p>
            <div className="mt-1 space-y-1">
              <p className="text-sm text-blue-700">
                REX Balance: {formatNumber(rexInfo.rex_balance, 'REX')}
              </p>
              <p className="text-sm text-blue-700">
                REX/TLOS Rate: {rexInfo.rex_price.toLocaleString(undefined, { 
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2 
                })}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountSummary;