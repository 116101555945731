// src/components/AccountActions.js
import React, { useState, useEffect, useCallback  } from 'react';
import { useChainContext } from '../contexts/ChainContext';
import BlockchainService from '../services/blockchainService';
import { ChevronLeft, ChevronRight,Loader2 } from 'lucide-react';
import ActionRenderer from './ActionRenderer';
import ActionFilter from './ActionFilter';
import { Link } from 'react-router-dom';

const AccountActions = ({ accountName, filters }) => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentChain } = useChainContext();

  const [totalActions, setTotalActions] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(20);

  useEffect(() => {
    const fetchActions = async () => {
      if (!accountName) {
        setError('Account name is undefined');
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const blockchainService = new BlockchainService(currentChain);

        let filter = '';
        if (filters.contractName) {
          filter = filters.contractName;
          if (filters.actionName) {
            filter += `:${filters.actionName}`;
          }
        }

        const queryParams = new URLSearchParams({
          limit: itemsPerPage.toString(),
          skip: ((currentPage - 1) * itemsPerPage).toString(),
          account: accountName,
          after: filters.after,
          before: filters.before,
          category: filters.category,
          simple: 'true',
          checkLib: 'true'
        });

        if (filter) {
          queryParams.append('filter', filter);
        }

        const response = await blockchainService.fetchFromChain(`/get_actions?${queryParams.toString()}`);
        setActions(response.simple_actions || []);
        setTotalActions(response.total?.value || 0);
        setError(null);
      } catch (err) {
        console.error('Error fetching account actions:', err);
        setError('Failed to fetch account actions: ' + err.message);
        setActions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchActions();
  }, [accountName, currentChain, filters, currentPage, itemsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleGoToPage = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(goToPage);
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setGoToPage('');
    }
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(totalActions / itemsPerPage);

  const getPaginationGroup = () => {
    let start = Math.max(currentPage - 2, 1);
    let end = Math.min(start + 4, totalPages);
    
    if (end === totalPages) {
      start = Math.max(end - 4, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  if (loading) {
    return (
      <div className="text-center py-8">
        <Loader2 className="animate-spin mx-auto mb-2" />
        Loading actions...
      </div>
    );
  }
  
  if (error) return (
    <div className="p-4 mx-auto max-w-2xl">
      <div className="bg-red-50 text-red-600 p-4 rounded-lg border border-red-200">
        Error: {error}
      </div>
    </div>
  );

  return (
    <div className="space-y-4 px-4 sm:px-6 lg:px-8">

      {/* Results per page selector */}
      <div className="bg-white p-3 rounded-lg shadow-md flex flex-wrap items-center justify-between gap-2">
        <span className="text-sm font-medium text-gray-700">Results per page:</span>
        <select
          value={itemsPerPage}
          onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
          className="ml-2 px-2 py-1 text-sm bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>

      {/* Actions Table */}
      {actions.length > 0 ? (
        <div className="bg-white rounded-lg shadow overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:px-6">TX</th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:px-6">Date</th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:px-6">Action</th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:px-6">Data</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {actions.map((action, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-3 py-4 whitespace-nowrap text-sm sm:px-6">
                      <Link
                        to={`/transaction/${action.transaction_id}`}
                        className="text-blue-600 hover:text-blue-800 relative group"
                      >
                        <span className="relative z-10">{action.transaction_id.substring(0, 8)}...</span>
                        <span className="absolute inset-x-0 bottom-0 h-0.5 bg-blue-800 transform scale-x-0 origin-center transition-transform duration-300 ease-out group-hover:scale-x-100"></span>
                      </Link>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap text-xs sm:text-sm text-gray-500 sm:px-6">
                      {new Date(action.timestamp).toLocaleString()}
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap text-xs sm:text-sm text-gray-500 sm:px-6">
                      {`${action.contract}::${action.action}`}
                    </td>
                    <td className="px-3 py-4 sm:px-6">
                      <ActionRenderer action={action} accountName={accountName} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="text-center py-8 bg-white rounded-lg shadow">
          <p className="text-gray-500">No actions found matching the current filters.</p>
        </div>
      )}

      {/* Pagination */}
      <div className="bg-white px-4 py-3 rounded-lg shadow sm:px-6">
        {/* Mobile pagination */}
        <div className="flex flex-col sm:hidden space-y-4">
          <div className="text-sm text-gray-700 text-center">
            <span className="font-medium">{((currentPage - 1) * itemsPerPage) + 1}</span>
            {' '}-{' '}
            <span className="font-medium">{Math.min(currentPage * itemsPerPage, totalActions)}</span>
            {' '}of{' '}
            <span className="font-medium">{totalActions}</span>
          </div>
          <div className="flex justify-between">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </div>

        {/* Desktop pagination */}
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{((currentPage - 1) * itemsPerPage) + 1}</span> to <span className="font-medium">{Math.min(currentPage * itemsPerPage, totalActions)}</span> of{' '}
              <span className="font-medium">{totalActions}</span> results
            </p>
          </div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            {getPaginationGroup().map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  page === currentPage
                    ? 'z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AccountActions;