import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useChainContext } from '../../../contexts/ChainContext';
import { Database, DollarSign, RefreshCw } from 'lucide-react';
import { getChainConfig } from '../../../config/chainConfig';
import BlockchainService from '../../../services/blockchainService';

const SellRam = () => {
  const { currentChain, user } = useChainContext();
  const [ramInfo, setRamInfo] = useState({ total: 0, available: 0 });
  const [ramPrice, setRamPrice] = useState(0);
  const [sellType, setSellType] = useState('ram'); // 'ram' or 'wax'
  const [quantity, setQuantity] = useState('');
  const [convertedQuantity, setConvertedQuantity] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [notification, setNotification] = useState(null);
  const [blockchainService, setBlockchainService] = useState(() => new BlockchainService(currentChain));

  const chainConfig = getChainConfig(currentChain);
  const chainSymbol = chainConfig.symbol;

  useEffect(() => {
    setBlockchainService(new BlockchainService(currentChain));
  }, [currentChain]);

  const fetchRamInfoAndPrice = useCallback(async () => {
    try {
      const response = await blockchainService.fetchFromChain('/get_ram_price');
      setRamPrice(response.ramPrice);
      
      if (user && user.name) {
        const accountInfo = await blockchainService.fetchFromChain(`/get_account/${user.name}`);
        setRamInfo({
          total: accountInfo.ram_quota,
          available: accountInfo.ram_quota - accountInfo.ram_usage
        });
      }
    } catch (error) {
      console.error('Error fetching RAM info and price:', error);
      setNotification({ message: 'Failed to fetch RAM information', type: 'error' });
    }
  }, [blockchainService, user]);

  useEffect(() => {
    fetchRamInfoAndPrice();
  }, [fetchRamInfoAndPrice]);

  const formatNumber = (number) => {
    return Number(number).toFixed(8);
  };

  const convertQuantity = useCallback((value) => {
    if (!value) return '';
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return '';
    
    if (sellType === 'ram') {
      // Convert RAM bytes to WAX
      return formatNumber((numValue * ramPrice) );
    } else {
      // Convert WAX to RAM bytes
      return Math.floor(numValue / ramPrice );
    }
  }, [sellType, ramPrice]);

  useEffect(() => {
    setConvertedQuantity(convertQuantity(quantity));
  }, [quantity, convertQuantity]);

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setQuantity(value);
    }
  };

  const formattedQuantity = useMemo(() => {
    if (!quantity) return '0.00000000';
    return formatNumber(parseFloat(quantity));
  }, [quantity]);

  const handleSellRam = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setNotification(null);

    try {
      if (!user || !user.session) {
        throw new Error('User not authenticated');
      }

      let bytes;
      if (sellType === 'ram') {
        bytes = Math.floor(parseFloat(quantity) * 1024); // Convert KB to bytes
      } else {
        bytes = Math.floor(parseFloat(convertedQuantity) * 1024); // Convert KB to bytes
      }

      const action = {
        account: 'eosio',
        name: 'sellram',
        authorization: [{ actor: user.name, permission: 'active' }],
        data: {
          account: user.name,
          bytes: bytes
        }
      };

      const result = await user.session.signTransaction(
        { actions: [action] },
        { broadcast: true, blocksBehind: 3, expireSeconds: 30 }
      );

      setNotification({ message: `Successfully sold ${bytes} bytes of RAM.`, type: 'success' });
      setQuantity('');
      setConvertedQuantity('');
      fetchRamInfoAndPrice(); // Refresh RAM info after successful transaction
      
    } catch (error) {
      console.error('Error selling RAM:', error);
      setNotification({ message: `Failed to sell RAM: ${error.message}`, type: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-8xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Sell RAM</h1>
        <div className="bg-gradient-to-br from-purple-500 to-indigo-600 p-1 rounded-2xl shadow-lg">
          <div className="bg-white p-6 rounded-2xl">
            <form onSubmit={handleSellRam} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">RAM Price</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    value={`${ramPrice.toFixed(8)} ${chainSymbol}/KB`}
                    disabled
                    className="flex-grow px-4 py-2 border border-gray-300 rounded-l-lg bg-gray-100 text-gray-600"
                  />
                  <button
                    type="button"
                    onClick={fetchRamInfoAndPrice}
                    className="px-4 py-2 bg-gray-200 rounded-r-lg hover:bg-gray-300 focus:outline-none"
                  >
                    <RefreshCw size={20} />
                  </button>
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Sell Type</label>
                <div className="flex space-x-4">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="ram"
                      checked={sellType === 'ram'}
                      onChange={() => setSellType('ram')}
                      className="form-radio h-5 w-5 text-purple-600"
                    />
                    <span className="ml-2">RAM (KBytes)</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="wax"
                      checked={sellType === 'wax'}
                      onChange={() => setSellType('wax')}
                      className="form-radio h-5 w-5 text-purple-600"
                    />
                    <span className="ml-2">{chainSymbol}</span>
                  </label>
                </div>
              </div>
              <div>
                <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-1">
                  {sellType === 'ram' ? 'RAM Quantity (KBytes)' : `${chainSymbol} Quantity`}
                </label>
                <div className="relative">
                  <input
                    id="quantity"
                    type="text"
                    value={quantity}
                    onChange={handleQuantityChange}
                    className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder={sellType === 'ram' ? 'Enter RAM in KBytes' : `Enter amount in ${chainSymbol}`}
                    required
                  />
                  {sellType === 'ram' ? (
                    <Database className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  ) : (
                    <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  )}
                </div>
                {sellType === 'wax' && (
                  <p className="mt-1 text-sm text-gray-500">
                    Formatted: {formattedQuantity} {chainSymbol}
                  </p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {sellType === 'ram' ? `Equivalent in ${chainSymbol}` : 'Equivalent in RAM (KBytes)'}
                </label>
                <input
                  type="text"
                  value={convertedQuantity}
                  disabled
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-lg hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center disabled:opacity-50"
                  disabled={isProcessing}
                >
                  <Database className="w-5 h-5 mr-2" />
                  {isProcessing ? 'Processing...' : 'Sell RAM'}
                </button>
              </div>
            </form>
          </div>
        </div>

        {notification && (
          <div className={`mt-4 p-4 rounded-md ${notification.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
            {notification.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default SellRam;