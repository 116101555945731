import React from 'react';

function Footer() {
  return (
    <footer className="bg-gradient-to-r from-gray-100 to-gray-200 border-t border-gray-300">
      <div className="container mx-auto px-4 py-3">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-center sm:text-left mb-2 sm:mb-0">
            <p className="text-gray-700 text-xs sm:text-sm font-medium">
              © {new Date().getFullYear()} Multi-Chain Blockchain Explorer and Wallet
            </p>
          </div>
          <div className="text-center sm:text-right">
            <p className="text-gray-700 text-xs sm:text-sm font-medium">
              Created by{' '}
              <a 
                href="https://eosiomadrid.io" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
              >
                EosioMadrid
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;