import React, { useState, useEffect } from 'react';
import { Database, Key, DollarSign, ChevronRight, ChevronDown, Users, Link as LinkIcon } from 'lucide-react';
import { useChainContext } from '../contexts/ChainContext';
import AccountSummary from './AccountSummary';
import ResourcesSection from './ResourcesSection';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AccountActions from './AccountActions';
import Tablas from './Tablas';
import ActionFilter from './ActionFilter';
import Acciones from './AccionesContract';

// Subcomponentes...
const TokensTab = ({ tokens, visibleTokens, totalTokens, loadMoreTokens, formatNumber }) => (
  <div className="space-y-4">
    <p className="text-sm text-gray-600">Total Tokens: {totalTokens}</p>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {tokens.slice(0, visibleTokens).map((token, index) => (
        <div key={index} className="flex items-center bg-gray-50 p-3 rounded-lg">
          <span className="w-8 h-8 bg-gray-200 rounded-full mr-3 flex items-center justify-center">
            <DollarSign size={16} />
          </span>
          <div>
            <p className="font-semibold">{token.contract}</p>
            <p className="text-sm text-gray-600">{formatNumber(token.amount)} {token.symbol}</p>
          </div>
        </div>
      ))}
    </div>
    {visibleTokens < totalTokens && (
      <button 
        onClick={loadMoreTokens}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
      >
        Load more
      </button>
    )}
  </div>
);

const KeyCard = ({ node }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="w-full">
      <div className="border border-gray-300 rounded-lg bg-white mb-2">
        {/* Header */}
        <div 
          className="p-3 cursor-pointer hover:bg-gray-50"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="flex items-center gap-2">
            <button className="text-gray-500">
              {node.children.length > 0 ? (
                isExpanded ? (
                  <ChevronDown className="w-4 h-4" />
                ) : (
                  <ChevronRight className="w-4 h-4" />
                )
              ) : (
                <div className="w-4" />
              )}
            </button>
            <Key className="w-4 h-4 text-gray-500" />
            <span className="font-semibold text-sm">{node.perm_name}</span>
            <span className="text-xs bg-gray-200 px-2 py-1 rounded">
              Threshold: {node.required_auth.threshold}
            </span>
          </div>
        </div>

        {/* Content */}
        {isExpanded && (
          <div className="px-3 pb-3">
            {/* Keys */}
            <div className="space-y-2">
              {node.required_auth.keys.map((key, index) => (
                <div
                  key={`key-${index}-${key.key}`}
                  className="flex flex-col sm:flex-row sm:items-center gap-2 ml-6 bg-gray-50 p-2 rounded"
                >
                  <div className="flex items-center gap-2 flex-1 min-w-0">
                    <Key className="w-3 h-3 shrink-0 text-gray-500" />
                    <span className="text-xs font-mono truncate">{key.key}</span>
                  </div>
                  <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded shrink-0">
                    +{key.weight}
                  </span>
                </div>
              ))}
            </div>

            {/* Accounts */}
            <div className="space-y-2 mt-2">
              {node.required_auth.accounts.map((account, index) => (
                <div
                  key={`account-${index}-${account.permission.actor}`}
                  className="flex flex-col sm:flex-row sm:items-center gap-2 ml-6 bg-gray-50 p-2 rounded"
                >
                  <div className="flex items-center gap-2 flex-1 min-w-0">
                    <Users className="w-3 h-3 shrink-0 text-gray-500" />
                    <span className="text-xs truncate">
                      {account.permission.actor}@{account.permission.permission}
                    </span>
                  </div>
                  <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded shrink-0">
                    +{account.weight}
                  </span>
                </div>
              ))}
            </div>

            {/* Linked Actions */}
            {node.linked_actions && node.linked_actions.length > 0 && (
              <div className="mt-3 ml-6">
                <span className="text-xs font-semibold">Linked Actions:</span>
                <div className="mt-2 space-y-2">
                  {node.linked_actions.map((action, index) => (
                    <div
                      key={`action-${index}-${action.account}`}
                      className="flex items-center gap-2 bg-gray-50 p-2 rounded"
                    >
                      <LinkIcon className="w-3 h-3 shrink-0 text-gray-500" />
                      <span className="text-xs truncate">
                        {action.account}::{action.action || '*'}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Children */}
      {isExpanded && node.children.length > 0 && (
        <div className="pl-4 space-y-2">
          {node.children.map((child) => (
            <KeyCard key={child.perm_name} node={child} />
          ))}
        </div>
      )}
    </div>
  );
};

const KeysTab = ({ keyTree, totalKeys }) => (
  <div className="space-y-4">
    <div className="flex items-center justify-between">
      <p className="text-sm text-gray-600">Total Keys: {totalKeys}</p>
    </div>
    <div className="space-y-4 max-w-full overflow-x-hidden">
      {Object.values(keyTree).map(node => (
        <KeyCard key={node.perm_name} node={node} />
      ))}
    </div>
  </div>
);

const VotesTab = ({ voterInfo }) => (
  <div className="space-y-4">
    <h3 className="text-lg font-semibold">Voting Information</h3>
    {voterInfo?.proxy ? (
      <div>
        <p className="text-sm text-gray-600">Proxy:</p>
        <p className="font-semibold">{voterInfo.proxy}</p>
      </div>
    ) : voterInfo?.producers?.length > 0 ? (
      <div>
        <p className="text-sm text-gray-600 mb-2">Producers:</p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
          {voterInfo.producers.map((producer, index) => (
            <div key={index} className="bg-gray-100 rounded p-2 text-center">
              <p className="text-xs font-medium text-gray-800 truncate">{producer}</p>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <p className="text-sm text-gray-600">No voting information available</p>
    )}
  </div>
);

const AccountOverview = ({ accountInfo }) => {
  const { currentChain } = useChainContext();
  const [activeTab, setActiveTab] = useState('Account');
  const [activeSubTab, setActiveSubTab] = useState('Tokens');
  const [visibleTokens, setVisibleTokens] = useState(3);
  const [keyTree, setKeyTree] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    category: '',
    contractName: '',
    actionName: '',
    after: '',
    before: '',
  });

  const countTotalPermissions = (tree) => {
    let count = 0;
    
    const countNodes = (node) => {
      count++; // Cuenta el nodo actual
      if (node.children) {
        node.children.forEach(child => countNodes(child));
      }
    };

    // Recorre cada nodo raíz del árbol
    Object.values(tree || {}).forEach(node => {
      countNodes(node);
    });

    return count;
  };


  useEffect(() => {
    if (accountInfo?.account?.permissions) {
      const tree = buildKeyTree(accountInfo.account.permissions);
      setKeyTree(tree);
    }
  }, [accountInfo]);

  const buildKeyTree = (permissions) => {
    const tree = {};
    const lookup = {};

    permissions.forEach(perm => {
      lookup[perm.perm_name] = { ...perm, children: [] };
    });

    permissions.forEach(perm => {
      if (perm.parent) {
        if (lookup[perm.parent]) {
          lookup[perm.parent].children.push(lookup[perm.perm_name]);
        }
      } else {
        tree[perm.perm_name] = lookup[perm.perm_name];
      }
    });

    return tree;
  };

  const chainToken = {
    'wax-mainnet': 'WAX',
    'wax-testnet': 'WAX',
    'telos-mainnet': 'TLOS',
    'telos-testnet': 'TLOS',
  }[currentChain] || 'TOKEN';

  const formatNumber = (num) => {
    if (typeof num === 'string') {
      const [value, symbol] = num.split(' ');
      return `${parseFloat(value).toLocaleString(undefined, { maximumFractionDigits: 8 })} ${symbol || chainToken}`;
    }
    return parseFloat(num).toLocaleString(undefined, { maximumFractionDigits: 8 });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const tokens = accountInfo.tokens || [];
  const totalTokens = tokens.length;
  //const totalKeys = keyTree ? Object.keys(keyTree).length : 0;
  const totalPermissions = keyTree ? countTotalPermissions(keyTree) : 0;

  const loadMoreTokens = () => {
    setVisibleTokens(prev => Math.min(prev + 3, totalTokens));
  };

  return (
    <main className="space-y-6">
      <AccountSummary accountInfo={accountInfo.account} />
      
      <Tabs selectedIndex={activeTab === 'Account' ? 0 : 1} onSelect={index => setActiveTab(index === 0 ? 'Account' : 'Contract')}>
        <TabList className="flex border-b border-gray-200 bg-white rounded-t-lg">
          {['Account', 'Contract'].map(tab => (
            <Tab
              key={tab}
              className={`px-6 py-4 font-medium text-base hover:bg-gray-100 cursor-pointer focus:outline-none transition-colors ${
                activeTab === tab ? 'text-blue-600 border-b-2 border-blue-500' : 'text-gray-500'
              }`}
            >
              {tab}
            </Tab>
          ))}
        </TabList>

        <div className="bg-white rounded-b-lg shadow-sm p-6">
          <TabPanel>
            <div className="space-y-6">
              <ResourcesSection accountInfo={accountInfo} />

              <nav className="flex space-x-8 border-b border-gray-200">
                {['Tokens', 'Keys', 'Votes'].map(tab => (
                  <button
                    key={tab}
                    onClick={() => setActiveSubTab(tab)}
                    className={`py-4 px-1 border-b-2 font-medium ${
                      activeSubTab === tab ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500'
                    }`}
                  >
                    {tab}
                    <span className={`ml-2 px-2.5 py-0.5 rounded-full text-sm ${
                      activeSubTab === tab ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900'
                    }`}>
                       {tab === 'Tokens' ? totalTokens : (tab === 'Keys' ? totalPermissions : 0)}
                    </span>
                  </button>
                ))}
              </nav>

              {activeSubTab === 'Tokens' && (
                <TokensTab
                  tokens={tokens}
                  visibleTokens={visibleTokens}
                  totalTokens={totalTokens}
                  loadMoreTokens={loadMoreTokens}
                  formatNumber={formatNumber}
                />
              )}
              {activeSubTab === 'Keys' && (
                <KeysTab
                  keyTree={keyTree}
                  totalKeys={totalPermissions}
                />
              )}
              {activeSubTab === 'Votes' && (
                <VotesTab voterInfo={accountInfo.account?.voter_info} />
              )}

              <section className="mt-6 space-y-4">
                <h2 className="text-xl font-semibold mb-4">Account Actions</h2>
                
                <div className="w-full bg-white p-4 rounded-lg shadow-md">
                  <ActionFilter 
                    filters={filters} 
                    onFilterChange={handleFilterChange} 
                    handleSubmit={() => setCurrentPage(1)} 
                  />
                </div>
                
                <div className="w-full bg-white p-4 rounded-lg shadow-md">
                  <AccountActions 
                    accountName={accountInfo.account.account_name} 
                    filters={filters} 
                  />
                </div>
              </section>
            </div>
          </TabPanel>

          <TabPanel>
  <section>
    <h2 className="text-xl font-semibold mb-4">Contract Details</h2>
    <Tabs>
      <TabList>
        <Tab>Tables</Tab>
        <Tab>Actions</Tab>
      </TabList>

      <TabPanel>
        <Tablas contractName={accountInfo.account.account_name} />
      </TabPanel>
      <TabPanel>
        <Acciones contractName={accountInfo.account.account_name} />
      </TabPanel>
    </Tabs>
  </section>
</TabPanel>

        </div>
      </Tabs>
    </main>
  );
};

export default AccountOverview;